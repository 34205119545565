import {
  MdCheck,
  MdArchive,
  MdArrowLeft,
  MdLabelOutline,
} from "react-icons/md";
import Search from "./Search";
import { BiPhone } from "react-icons/bi";
import { CgSortAz } from "react-icons/cg";
import { useState, useRef, useEffect } from "react";
import { FiMessageSquare, FiX } from "react-icons/fi";
import { useLocation, useNavigate } from "react-router-dom";
import { AiOutlineDelete, AiOutlineEdit } from "react-icons/ai";

function Sidebar({
  contacts,
  labelInput,
  editLabelId,
  labels = [],
  onAddContact,
  showArchived,
  setLabelInput,
  handleAddLabel,
  editLabelValue,
  setEditLabelId,
  setShowArchived,
  handleEditLabel,
  handleDeleteLabel,
  setEditLabelValue,
  setSelectedContact,
  checkUserExistence,
  handleNavigateHome,
}) {
  let navigate = useNavigate();
  const modalRef = useRef(null);
  const location = useLocation();
  const sortModalRef = useRef(null);
  const sortButtonRef = useRef(null);
  const labelButtonRef = useRef(null);
  const [labelText, setLabelText] = useState("");
  const [editingLabel, setEditingLabel] = useState(null);
  const [headerText, setHeaderText] = useState("All Contacts");
  const [isSortModalOpen, setIsSortModalOpen] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);

  // Handle noteModal modal escape/close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setIsLabelModalOpen(false);
      }
    };
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setIsLabelModalOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle click outside of the modal
  useEffect(() => {
    function handleClickOutside(event) {
      if (
        isSortModalOpen &&
        sortModalRef.current &&
        !sortModalRef.current.contains(event.target) &&
        !sortButtonRef.current.contains(event.target)
      ) {
        setIsSortModalOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [isSortModalOpen]);

  // Updates 'showArchived' and 'headerText' based on URL pathname.
  useEffect(() => {
    if (location.pathname === "/starconnect/") {
      setHeaderText("All Contacts");
      setShowArchived(false);
    }
    if (location.pathname === "/starconnect/archive") {
      setShowArchived(true);
      setHeaderText("Archive");
    } else {
      setShowArchived(false);
    }
  }, [location.pathname, setShowArchived]);

  const activeContacts = contacts.filter((contact) => !contact.archived);
  const archivedContacts = contacts.filter((contact) => contact.archived);

  // Handle archive button click
  const handleArchiveClick = () => {
    setShowArchived(true);
    setHeaderText("Archive");
    navigate("/starconnect/archive");
  };

  // Handle the clicking of cancel
  const handleCancelEdit = () => {
    setEditingLabel(null);
    setLabelText("");
    setEditLabelId(null);
    setEditLabelValue("");
  };

  // Save edited changes
  const handleSaveEdit = () => {
    if (editLabelValue.trim() !== "") {
      handleEditLabel(editLabelId, labelText);
    }
    setEditingLabel(null);
    setLabelText("");
    setEditLabelId(null);
    setEditLabelValue("");
  };

  // Handle the message icon click
  const handleMessageClick = async (contact) => {
    try {
      if (!contact.email) {
        alert(
          `No associated email found with contact ${contact.firstName} ${contact.lastName}`
        );
        return;
      }
      const userExists = await checkUserExistence(contact.email);
      if (userExists) {
        navigate(
          "/starchat/chat/" +
            contact.firstName.toLowerCase() +
            contact.lastName.toLowerCase()
        );
      } else {
        alert(
          "The other participant must be registered on starrvault in order to chat with them."
        );
      }
    } catch (error) {
      console.error("Error checking user existence:", error);
      alert(
        "There was an error trying to check user existence. Please try again."
      );
    }
  };

  return (
    <div className="w-72 h-[calc(100vh-1.8rem)] bg-gray-100 border-r border-gray-200 rounded-tl-lg rounded-bl-lg relative">
      <h1
        className="text-xl font-bold text-gray-800 mt-4 ml-4 cursor-pointer"
        onClick={handleNavigateHome}
      >
        Star Connect
      </h1>
      <div className="w-72 border-t border-gray-300 my-2" />
      <div className="flex flex-col space-y-3 px-4 py-2">
        <Search onAddContact={onAddContact} />

        {/* Top-left menu */}
        <div className="flex justify-between relative">
          <>
            <button
              className="flex items-center px-2 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 cursor-pointer duration-200 ease-in-out"
              onClick={handleArchiveClick}
            >
              <MdArchive size={20} className="mr-2" />
              <span>Archive</span>
            </button>
            <button
              className="flex items-center px-2 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 cursor-pointer duration-200 ease-in-out"
              onClick={() => setIsLabelModalOpen(true)}
              ref={labelButtonRef}
            >
              <MdLabelOutline size={20} className="mr-2" />
              <span>Labels</span>
            </button>
            <button
              className="flex items-center px-2 py-2 bg-gray-200 text-gray-700 rounded-md hover:bg-gray-300 cursor-pointer duration-200 ease-in-out"
              onClick={() => setIsSortModalOpen(!isSortModalOpen)}
              ref={sortButtonRef}
            >
              <CgSortAz size={20} title="Sort contacts" />
            </button>

            {/* Contact Sorting Modal */}
            {isSortModalOpen && (
              <div
                className="absolute bg-white shadow-lg shadow-gray-300 rounded-md py-3 pl-2 pr-4 z-50 border border-gray-300 overflow-y-auto custom-scrollbar max-h-48"
                style={{
                  top:
                    labelButtonRef.current.offsetTop +
                    labelButtonRef.current.offsetHeight -
                    44,
                  left: labelButtonRef.current.offsetLeft + 150,
                }}
                ref={sortModalRef}
              >
                <div className="absolute left-[-7px] top-4 h-0 w-0 border-t-8 border-t-transparent border-b-8 border-b-transparent border-r-8 border-r-white" />
                <span className="w-full font-semibold ml-3 text-gray-700 -mt-1">
                  Labels
                </span>
                <div className="border-t border-gray-300 mt-2 -ml-2 -mr-4" />
                {labels.map((label) => (
                  <div
                    key={label.labelName}
                    className="flex items-center text-gray-800 mt-3 cursor-pointer"
                  >
                    <MdLabelOutline size={19} className="mr-1" />

                    <>
                      <span className="text-sm font-medium mt-[0.15rem]">
                        {label.labelName.length > 5
                          ? `${label.labelName.slice(0, 5)}...`
                          : label.labelName}
                      </span>
                    </>
                  </div>
                ))}
              </div>
            )}

            {/* Label Modal */}
            {isLabelModalOpen && (
              <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                <div
                  className="relative bg-white rounded-lg shadow-md p-4 space-y-3"
                  ref={modalRef}
                >
                  <div className="flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-gray-800">
                      Labels
                    </h2>
                    <FiX
                      className="text-lg text-gray-700 cursor-pointer"
                      onClick={() => setIsLabelModalOpen(false)}
                      title="Close"
                    />
                  </div>
                  <div className="border-t border-gray-300 mt-2 -mx-4" />
                  <div className="flex items-center mt-2">
                    <input
                      type="text"
                      placeholder="Add a label"
                      className="w-48 border rounded-md p-1"
                      value={labelInput}
                      onChange={(e) => setLabelInput(e.target.value)}
                    />
                    <button
                      className="text-green-500 ml-2"
                      onClick={() => handleAddLabel(labelInput)}
                    >
                      <MdCheck size={20} />
                    </button>
                  </div>
                  <div
                    className={`flex flex-col ${
                      labels.length > 5
                        ? "max-h-40 overflow-y-auto custom-scrollbar"
                        : ""
                    }`}
                  >
                    {labels.map((label, index) => (
                      <div
                        key={label.labelName}
                        className="flex items-center mt-3"
                      >
                        <MdLabelOutline size={20} className="mr-1" />
                        {editingLabel === label.labelName ? (
                          <>
                            <input
                              type="text"
                              value={labelText}
                              onChange={(e) => setLabelText(e.target.value)}
                              className="w-24 border rounded-md p-1 mr-2"
                            />
                            <div className="ml-auto flex space-x-2">
                              <button
                                className="text-green-500"
                                onClick={handleSaveEdit}
                              >
                                <MdCheck size={20} />
                              </button>
                              <button
                                className="text-gray-500 hover:text-red-500"
                                onClick={handleCancelEdit}
                              >
                                <FiX size={20} />
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            <span className="font-medium">
                              {label.labelName}
                            </span>
                            <div className="ml-auto flex space-x-2">
                              <button
                                className="text-gray-500 hover:text-gray-700"
                                onClick={() => {
                                  setEditLabelId(index);
                                  setEditLabelValue(label.labelName);
                                  setLabelText(label.labelName);
                                  setEditingLabel(label.labelName);
                                }}
                              >
                                <AiOutlineEdit size={18} />
                              </button>
                              <button
                                className="text-gray-500 hover:text-red-500"
                                onClick={() => handleDeleteLabel(index)}
                              >
                                <AiOutlineDelete size={18} />
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </>
        </div>
      </div>

      {/* Contact Display */}
      <div className="w-72 border-t flex justify-center border-gray-300 py-2 mt-2">
        {headerText === "Archive" && (
          <MdArrowLeft
            size={24}
            className="rounded  p-1 hover:bg-gray-300 absolute left-2 cursor-pointer"
            onClick={() => {
              navigate("/starconnect");
              setHeaderText("All Contacts");
            }}
            title="See all contacts"
          />
        )}
        <span className="font-semibold mx-auto">{headerText}</span>
      </div>
      <div className="w-[17.9rem] border-t border-gray-300" />
      <div
        className={`divide-y h-[calc(100vh-16rem)] divide-gray-200 custom-scrollbar ${
          contacts.length > 7 ? "overflow-y-scroll" : ""
        }`}
      >
        <ul>
          {(showArchived ? archivedContacts : activeContacts).map((contact) => (
            <li
              key={contact.id}
              className="p-4 hover:bg-gray-200 cursor-pointer flex items-center duration-150 ease-in-out"
              onClick={() => setSelectedContact(contact)}
            >
              <span>{contact.firstName + " " + contact.lastName}</span>
              <BiPhone
                size={16}
                className="ml-auto"
                title={`Call ${contact.firstName + " " + contact.lastName}`}
              />
              <FiMessageSquare
                size={16}
                className="ml-3"
                title={`Message ${contact.firstName + " " + contact.lastName}`}
                onClick={() => handleMessageClick(contact)}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
