import React from "react";

function LandingPage() {
  return (
    <div className="justify-center lg:mt-24 md:mt-14 text-gray-600 p-8">
      <div className="text-center md:text-left">
        <h1 className="text-6xl font-bold mb-6">Welcome, beta-testers</h1>
        <div className="md:ml-2">
          <p className="text-lg mb-8">
            A beta-tester is a person who tests out the beta-version of a
            website, which is typically released to a small number of people
            before releasing a final version to the public. The responsibilities
            of a beta-tester are to use the website extensively and report all
            the bugs/suggestions. Want to be a beta-tester for{" "}
            <span className="font-semibold">starrvault</span>?
          </p>
          <span className="bg-white text-yellow-400 font-semibold">
            Become a beta-tester?
          </span>
          <br />
          <span>
            Send 'hi' to <i>starrvault24@gmail.com</i> on mail.
          </span>
        </div>
      </div>
    </div>
  );
}

export default LandingPage;
