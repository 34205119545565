import SettingsSidebar from "../appComponents/settingsComponents/SettingsSidebar";
import { Outlet } from "react-router-dom";

function Settings({ isNavOpen }) {
  return (
    <div
      className={`flex h-[calc(100vh-1.8rem)] -mt-1 ${
        isNavOpen ? "ml-72" : "ml-14"
      }`}
    >
      <div className="flex p-6 border border-gray-300 rounded w-full">
        <SettingsSidebar />
        <div className="w-px h-[calc(100vh-1.85rem)] bg-gray-300 -mt-6 mx-6" />
        <div className="flex-grow">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default Settings;
