import { FiX } from "react-icons/fi";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function RegistrationInfo() {
  const registerPageModalRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Check if user logged in
  useEffect(() => {
    if (auth.currentUser) {
      setIsLoggedIn(true);
    }
  }, []);

  // Handle clicks outside the modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        registerPageModalRef.current &&
        !registerPageModalRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Registering on Starrvault
      </h1>

      <p className="text-gray-700 mb-8">
        Signing up for Starrvault opens the door to a world of secure
        communication, easy note-taking, and efficient contact management.
        Whether you're an individual looking to streamline your digital life or
        a professional seeking a secure platform to collaborate, Starrvault
        offers a range of benefits designed to meet your needs.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Why Should You Register on Starrvault?
      </h2>

      <p className="text-gray-700 mb-3">
        Creating an account on Starrvault unlocks the full potential of our
        platform. Here are some of the key benefits and features you'll get
        access to once you register:
      </p>

      <ul className="list-disc ml-10 mb-8 space-y-2 text-gray-700">
        <li>
          <strong>Secure Messaging with Star Chat:</strong> Communicate with
          friends, family, and colleagues securely using encrypted messaging.
          You can also make voice and video calls directly within the app.
        </li>
        <li>
          <strong>Effortless Note-Taking with Star Note:</strong> Organize your
          thoughts, projects, and reminders all in one place with our
          easy-to-use note-taking tool. Starrvault keeps your notes safe and
          accessible across all your devices.
        </li>
        <li>
          <strong>Manage Contacts with Star Connect:</strong> Keep your contacts
          organized and within reach at all times. Starrvault helps you easily
          add, edit, and categorize your contacts so that you're always in touch
          with the people who matter.
        </li>
        <li>
          <strong>Data Privacy and Security:</strong> We prioritize your
          security by using top-tier encryption across all features, ensuring
          your data is safe from unauthorized access.
        </li>
        <li>
          <strong>Personalized Experience:</strong> Customize the look and feel
          of Starrvault to suit your preferences and get access to real-time
          updates and new features as they roll out.
        </li>
      </ul>

      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg shadow mb-8">
        <h2 className="text-2xl font-semibold text-blue-800 mb-2">
          How to Register
        </h2>
        <p className="text-blue-700 mb-2">
          Creating an account on Starrvault is simple and takes just a few
          minutes. Follow these steps to get started:
        </p>

        <ul className="ml-6 text-blue-700 list-decimal list-inside">
          <li className="mb-2">
            <strong>Visit the registration page:</strong> Head over to the{" "}
            {isLoggedIn ? (
              <span
                className="text-purple-700 hover:underline underline-offset-2 cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                registration page
              </span>
            ) : (
              <Link to="/register">
                <span className="text-purple-700 hover:underline underline-offset-2">
                  registration page
                </span>
              </Link>
            )}{" "}
            to create your account.
          </li>
          <li className="mb-2">
            <strong>Create your account:</strong> Fill in your details,
            including your name, email address, and a strong password. Make sure
            to create a secure password to protect your account.
          </li>
          <li className="mb-2">
            <strong>Verification email:</strong> After clicking the "Register"
            button, you will receive a verification email. Be sure to check your
            inbox (and possibly your spam folder). The verification link in the
            email will expire within a few minutes, so make sure to verify your
            account promptly by clicking the link.
          </li>
          <li className="mb-2">
            <strong>Start using Starrvault:</strong> Once you've verified your
            email, return to
            <Link to="/" className="text-purple-500 hover:underline ml-1">
              Starrvault
            </Link>{" "}
            and log in to start enjoying secure communication, note-taking, and
            contact management features. Have fun exploring the platform and
            making Starrvault a part of your digital life!
          </li>
        </ul>
      </div>

      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Tips for Creating a Strong Password
      </h2>

      <p className="text-gray-700 mb-3">
        When registering, it's important to create a password that is both
        strong and memorable. Here are a few tips:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-disc list-inside">
        <li className="mb-2">Use a mix of upper and lowercase letters.</li>
        <li className="mb-2">
          Include numbers and special characters (!, @, #, etc.).
        </li>
        <li className="mb-2">
          Avoid using easily guessable information, like your name or birthdate.
        </li>
        <li className="mb-2">Make it at least 8-12 characters long.</li>
      </ul>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={registerPageModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Important Note
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
              You cannot access the register page as you are <b>logged in</b>{" "}
              and already have an account.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default RegistrationInfo;
