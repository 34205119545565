import { auth } from "../firebase";
import { onAuthStateChanged } from "firebase/auth";
import React, { useState, createContext, useEffect, useMemo } from "react";

// Create a context for authentication data
export const AuthContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);

  // useEffect to set up a listener for authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, setCurrentUser);
    return () => unsubscribe(); // Cleanup function
  }, []);

  const value = useMemo(() => ({ currentUser }), [currentUser]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
