import { FiX } from "react-icons/fi";
import { auth } from "../../firebase";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";

function LoginInfo() {
  const loginModalRef = useRef(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Check if user logged in
  useEffect(() => {
    if (auth.currentUser) {
      setIsLoggedIn(true);
    }
  }, []);

  // Handle clicks outside the modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        loginModalRef.current &&
        !loginModalRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [loginModalRef]);

  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Logging into Starrvault
      </h1>

      <p className="text-gray-700 mb-8">
        Logging into your Starrvault account is simple and secure. Whether you
        want to continue a conversation, access your notes, or manage your
        contacts, follow the steps below to sign in to your account.
      </p>

      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg shadow mb-8">
        <h2 className="text-2xl font-semibold text-blue-800 mb-2">
          How to Log In
        </h2>

        <p className="text-blue-700 mb-2">
          To log into your account, you'll need the email address and password
          you used during registration. Follow these steps to get started:
        </p>

        <ul className="ml-6 text-blue-700 list-inside">
          <div className="p-2 rounded-xl border-2 border-blue-100">
            <li className="mb-2">
              <i>Step 1:</i> Go to the{" "}
              {isLoggedIn ? (
                <span
                  className="text-purple-700 hover:underline underline-offset-2 cursor-pointer"
                  onClick={() => setIsModalOpen(true)}
                >
                  login page
                </span>
              ) : (
                <Link to="/login">
                  <span className="text-purple-700 hover:underline underline-offset-2">
                    login page
                  </span>
                </Link>
              )}{" "}
              and enter your registered email address and password in the
              respective fields.
            </li>
            <li>
              <i>Step 2:</i> Once you've entered your credentials, click the
              "Log In" button. If your details are correct, you'll be directed
              to your dashboard, where you can access all of Starrvault's
              features.
            </li>
          </div>
          <li className="mt-3">
            <i>Extra Note:</i> If you've forgotten your password, you can click
            on the "Forgot Password?" link to initiate the password reset
            process.
          </li>
        </ul>
      </div>

      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Facing Login Issues?
      </h2>

      <p className="text-gray-700 mb-4">
        If you're having trouble logging in, don't worry. Sometimes, login
        issues can occur due to server delays, incorrect credentials, or other
        temporary glitches. Here's what you can do:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-disc list-inside">
        <li className="mb-2">
          <strong>Try again after a few hours:</strong> If you're facing issues
          like incorrect password warnings or timeouts, we recommend waiting for
          3-4 hours. In some cases, you may need to wait up to 6-8 hours before
          trying again.
        </li>
        <li className="mb-2">
          <strong>Clear browser cache and cookies:</strong> Sometimes, login
          problems may be due to stored browser data. Clear your cache and
          cookies and try logging in again.
        </li>
      </ul>

      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Need Further Assistance?
      </h2>

      <p className="text-gray-700 mb-8">
        If you're still unable to log in after multiple attempts and waiting,
        feel free to reach out to us for support. Please write to{" "}
        <strong>starrvault24@gmail.com</strong>. When writing your email, be
        clear and concise about the issue you're facing so that we can assist
        you as quickly as possible.
      </p>

      <p className="text-gray-700">
        Our support team will respond to your inquiry shortly after receiving
        your email, and we'll work to resolve your issue promptly.
      </p>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={loginModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Important Note
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
              You cannot access the login page as you are <b>logged in</b> and
              already have an account.
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default LoginInfo;
