import React from "react";

function StarConnectSettings() {
  return (
    <div className="p-4 space-y-6">
      <h2 className="mb-4 text-xl font-medium">Star Connect</h2>
    </div>
  );
}

export default StarConnectSettings;
