import { useState } from "react";
import { Link } from "react-router-dom";
import {
  FiStar,
  FiMessageSquare,
  FiEdit,
  FiUsers,
  // FiLock,
} from "react-icons/fi";
import UserProfileTile from "./UserProfileTile";

function Nav({ isNavOpen, setIsNavOpen }) {
  const [isCollapsed, setIsCollapsed] = useState(false);

  // Function to toggle value of nav
  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div
      className={`fixed top-2 left-2 h-[calc(100%-1rem)] ${
        isCollapsed ? "w-16" : "w-64"
      } bg-custom-gray-1 text-white flex flex-col rounded-lg shadow-lg transition-width duration-300`}
    >
      <div className="py-4 text-center text-xl font-semibold border-b border-gray-700 rounded-t-lg flex items-center justify-between">
        <div className="flex items-center ml-4">
          <FiStar className="text-yellow-400 mr-1" size="18" />
          {!isCollapsed && <Link to="/">starrvault</Link>}
        </div>
      </div>
      <div className="flex-grow flex flex-col items-start">
        <ul
          className={`mt-4 ${
            isCollapsed ? "space-y-6" : "space-y-4"
          } list-none w-full`}
        >
          <li
            className={`font-medium text-md cursor-pointer flex ${
              isCollapsed ? "justify-center" : "items-center pl-4"
            } ${isCollapsed ? "mb-6" : "mb-2"}`}
          >
            <Link
              to="/starchat"
              className="flex items-center w-full text-custom-gray-2"
            >
              <FiMessageSquare
                className={`${
                  isCollapsed ? "mx-auto" : "mr-2"
                } text-custom-gray-2`}
                size="18"
              />
              {!isCollapsed && "Star Chat"}
            </Link>
          </li>
          <li
            className={`font-medium text-md cursor-pointer flex ${
              isCollapsed ? "justify-center" : "items-center pl-4"
            } ${isCollapsed ? "mb-6" : "mb-2"}`}
          >
            <Link
              to="/starnote"
              className="flex items-center w-full text-custom-gray-2"
            >
              <FiEdit
                className={`${
                  isCollapsed ? "mx-auto" : "mr-2"
                } text-custom-gray-2`}
                size="18"
              />
              {!isCollapsed && "Star Note"}
            </Link>
          </li>
          <li
            className={`font-medium text-md cursor-pointer flex ${
              isCollapsed ? "justify-center" : "items-center pl-4"
            } ${isCollapsed ? "mb-6" : "mb-2"}`}
          >
            <Link
              to="/starconnect"
              className="flex items-center w-full text-custom-gray-2"
            >
              <FiUsers
                className={`${
                  isCollapsed ? "mx-auto" : "mr-2"
                } text-custom-gray-2`}
                size="18"
              />
              {!isCollapsed && "Star Connect"}
            </Link>
          </li>
          {/* <li
            className={`relative font-medium text-md cursor-not-allowed flex ${
              isCollapsed ? "justify-center" : "items-center pl-4"
            } ${isCollapsed ? "mb-6" : "mb-2"}`}
          >
            <Link
              to="/starsafe"
              className="flex items-center w-full text-custom-gray-2"
              style={{ filter: "blur(4px)" }} // Apply blur effect
              aria-disabled="true"
            >
              <FiLock
                className={`${
                  isCollapsed ? "mx-auto" : "mr-2"
                } text-custom-gray-2`}
                size="18"
              />
              {!isCollapsed && "Star Safe"}
            </Link>
            <span
              className={`absolute inset-0 flex items-center ${
                isCollapsed ? "text-xs text-center" : "ml-4 text-md"
              } text-custom-gray-2 bg-opacity-60 rounded-lg`}
              aria-hidden="true"
            >
              Coming Soon
            </span>
          </li> */}
        </ul>
      </div>
      <UserProfileTile
        isCollapsed={isCollapsed}
        toggleCollapse={toggleCollapse}
      />
    </div>
  );
}

export default Nav;
