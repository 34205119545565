import {
  doc,
  setDoc,
  getDoc,
  deleteDoc,
  updateDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../firebase";
import Sidebar from "./components/Sidebar";
import { useContext, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { ChatProvider } from "../context/ChatContext";
import ExpandedChatView from "./components/ExpandedChatView";
import ExpandedArchiveView from "./components/ExpandedArchiveView";
import { Route, Routes, useNavigate, useParams } from "react-router-dom";

function StarChat({ isNavOpen }) {
  let navigate = useNavigate();
  const { currentUser } = useContext(AuthContext);
  const [chatDocId, setChatDocId] = useState(null);

  // Handle the display of chat details based on URL paramter.
  const ActiveChatDetail = () => {
    const { name } = useParams();
    return (
      <ExpandedChatView
        name={name}
        pinChat={pinChat}
        chatDocId={chatDocId}
        blockUser={blockUser}
        deleteChat={deleteChat}
        archiveChat={archiveChat}
        archiveChatCopy={archiveChatCopy}
      />
    );
  };

  // Handle the display of chat details based on URL paramter.
  const ArchivedChatDetail = () => {
    const { name } = useParams();
    return (
      <ExpandedArchiveView
        name={name}
        chatDocId={chatDocId}
        blockUser={blockUser}
        deleteChat={deleteChat}
        unarchiveChat={unarchiveChat}
        deleteReadOnlyCopy={deleteReadOnlyCopy}
      />
    );
  };

  // Function to reset the chatDocId
  const resetChatDocId = () => {
    setChatDocId(null);
  };

  // Handle action 'PIN CHAT'
  const pinChat = async () => {
    try {
      const chatDocRef = doc(db, "chats", chatDocId);
      const chatDocSnap = await getDoc(chatDocRef);
      // Exit if chatDoId doesn't exist
      if (!chatDocSnap.exists()) return;

      const chatData = chatDocSnap.data();
      const { participant1, participant2, pinnedByP1, pinnedByP2 } = chatData;
      const updateFields = {};

      // If the current user is participant1, toggle 'pinnedByP1'
      if (participant1 === currentUser.email) {
        updateFields.pinnedByP1 = !pinnedByP1;
      }
      // If the current user is participant2, toggle 'pinnedByP2'
      else if (participant2 === currentUser.email) {
        updateFields.pinnedByP2 = !pinnedByP2;
      }

      await updateDoc(chatDocRef, updateFields);
    } catch (error) {
      // Catch error
      alert("Error pinning chat");
    }
  };

  // Handle action 'DELETE CHAT'
  const deleteChat = async (setShowChatDeleteModal) => {
    try {
      const docRef = doc(db, "chats", chatDocId);
      const docSnap = await getDoc(docRef);

      if (!docSnap.exists()) return;

      const chatData = docSnap.data();

      // If P1 is currentUser
      if (chatData.participant1 === currentUser.email) {
        // Check if P2 has already deleted the chat
        if (chatData.P2Status === "deleted") {
          // Both participants have deleted the chat, so delete the document
          await deleteDoc(docRef);
          navigate("/starchat");
        } else {
          // Update P1Status if P2 hasn't deleted the chat
          await updateDoc(docRef, { P1Status: "deleted" });
          setShowChatDeleteModal(false);
          navigate("/starchat");
        }
      }

      // If P2 is currentUser
      if (chatData.participant2 === currentUser.email) {
        // Check if P1 has already deleted the chat
        if (chatData.P1Status === "deleted") {
          // Both participants have deleted the chat, so delete the document
          await deleteDoc(docRef);
          navigate("/starchat");
        } else {
          // Update P2Status if P1 hasn't deleted the chat
          await updateDoc(docRef, { P2Status: "deleted" });
          setShowChatDeleteModal(false);
          navigate("/starchat");
        }
      }
    } catch (error) {
      alert(error);
      console.error("Error deleting chat: ", error);
    }
  };

  // Handle action 'ARCHIVE A COPY'
  const archiveChatCopy = async (setShowArchiveModal) => {
    try {
      // Retrieve the entire chat document using chatDocId
      const chatDocRef = doc(db, "chats", chatDocId);
      const chatDocSnapshot = await getDoc(chatDocRef);

      if (chatDocSnapshot.exists()) {
        const chatData = chatDocSnapshot.data();
        const { messages, participant1, participant2 } = chatData;

        // Check if there's at least one message from each participant
        const hasMessageFromParticipant1 = messages.some(
          (message) => message.sender === participant1
        );
        const hasMessageFromParticipant2 = messages.some(
          (message) => message.sender === participant2
        );

        if (hasMessageFromParticipant1 && hasMessageFromParticipant2) {
          // Create a new document in the 'chats' collection
          const newChatRef = doc(collection(db, "chats"));
          await setDoc(newChatRef, {
            status: "read-only-archive",
            messages,
            participant1,
            participant2,
            archivedAt: serverTimestamp(),
            archivedBy: currentUser.email,
          });

          setShowArchiveModal(false);
          alert("Chat successfully archived!");
        } else {
          alert(
            "You and the person you're chatting with must have sent at least one message to archive a copy."
          );
          setShowArchiveModal(false);
        }
      } else {
        alert("Chat document does not exist");
      }
    } catch (error) {
      alert(error.message);
    }
  };

  // Handle action 'ARCHIVE CHAT'
  const archiveChat = async (setShowArchiveModal) => {
    try {
      const chatDocRef = doc(db, "chats", chatDocId);
      const chatDocSnapData = await getDoc(chatDocRef);

      if (chatDocSnapData.exists()) {
        const chatData = chatDocSnapData.data();
        const { messages, participant1, participant2 } = chatData;

        // Check if there's at least one message from each participant
        const hasMessageFromParticipant1 = messages.some(
          (message) => message.sender === participant1
        );
        const hasMessageFromParticipant2 = messages.some(
          (message) => message.sender === participant2
        );

        if (hasMessageFromParticipant1 && hasMessageFromParticipant2) {
          const updateData = {};

          // If currentUser is P1
          if (participant1 === currentUser.email) {
            updateData.P1Status = "archived";
            if (chatData.pinnedByP1 === true) {
              updateData.pinnedByP1 = false;
            }
          }
          // If currentUser is P2
          else if (participant2 === currentUser.email) {
            updateData.P2Status = "archived";
            if (chatData.pinnedByP2 === true) {
              updateData.pinnedByP2 = false;
            }
          }

          // Update document with necessary fields
          await updateDoc(chatDocRef, updateData);
          setShowArchiveModal(false);
          navigate("/starchat");
        } else {
          alert(
            "You and the person you're chatting with must have sent at least one message to archive a copy."
          );
          setShowArchiveModal(false);
        }
      } else {
        alert("Chat document does not exist");
      }
    } catch (error) {
      alert(error);
    }
  };

  // Handle action 'UNARCHIVE CHAT'
  const unarchiveChat = async (setShowUnarchiveModal) => {
    try {
      const chatDocRef = doc(db, "chats", chatDocId);
      const chatDocSnapData = await getDoc(chatDocRef);

      if (chatDocSnapData.exists()) {
        const chatData = chatDocSnapData.data();

        // If currentUser is P1
        if (chatData.participant1 === currentUser.email) {
          await updateDoc(chatDocRef, { P1Status: "active" });
          setShowUnarchiveModal(false);
          navigate("/starchat/archive");
        }
        // If currentUser is P2
        else if (chatData.participant2 === currentUser.email) {
          await updateDoc(chatDocRef, { P2Status: "active" });
          setShowUnarchiveModal(false);
          navigate("/starchat/archive");
        }
      }
    } catch {
      alert("An error occured");
    }
  };

  // Handle action 'BLOCK USER'
  const blockUser = async (setShowBlockUserModal) => {
    try {
      const chatDocRef = doc(db, "chats", chatDocId);
      const chatDocSnap = await getDoc(chatDocRef);

      // Exit if chatDocId doesn't exist
      if (!chatDocSnap.exists()) return;

      const chatData = chatDocSnap.data();
      const { participant1, participant2 } = chatData;

      // Default values for P1BlockedP2 and P2BlockedP1 if they don't exist
      const P1BlockedP2 = chatData.P1BlockedP2 ?? false;
      const P2BlockedP1 = chatData.P2BlockedP1 ?? false;

      // If participant1 is currentUser, toggle blocked status for P1BlockedP2
      if (participant1 === currentUser.email) {
        await updateDoc(chatDocRef, {
          P1BlockedP2: !P1BlockedP2 || chatData.P1BlockedP2 === undefined,
          P1BlockedAt: serverTimestamp(),
        });
        setShowBlockUserModal(false);
      }

      // If participant2 is currentUser, toggle blocked status for P2BlockedP1
      if (participant2 === currentUser.email) {
        await updateDoc(chatDocRef, {
          P2BlockedP1: !P2BlockedP1 || chatData.P2BlockedP1 === undefined,
          P2BlockedAt: serverTimestamp(),
        });
        setShowBlockUserModal(false);
      }
    } catch (error) {
      alert(error);
    }
  };

  // Handle action 'DELETE READ-ONLY COPY'
  const deleteReadOnlyCopy = async (showChatCopyDeleteModal) => {
    try {
      const chatDocRef = doc(db, "chats", chatDocId);
      const docSnap = await getDoc(chatDocRef);

      // Exit if docSnap doesn't exist
      if (!docSnap.exists()) return;

      // Delete the read-only copy
      await deleteDoc(chatDocRef);
      showChatCopyDeleteModal(false);
      navigate("/starchat/archive");
    } catch (error) {
      alert("An error occured.");
    }
  };

  return (
    <ChatProvider>
      <div
        className={`flex h-[calc(100vh-1.8rem)] -mt-1 ${
          isNavOpen ? "ml-72" : "ml-14"
        }`}
      >
        <Sidebar setChatDocId={setChatDocId} resetChatDocId={resetChatDocId} />
        <Routes>
          <Route
            path="/"
            element={<ExpandedChatView chatDocId={chatDocId} />}
          />
          <Route path="chat/:name" element={<ActiveChatDetail />} />
          <Route path="archive" element={<ExpandedArchiveView />} />
          <Route path="archive/:name" element={<ArchivedChatDetail />} />
        </Routes>
      </div>
    </ChatProvider>
  );
}

export default StarChat;
