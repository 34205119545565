import { GiSpottedBug } from "react-icons/gi";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function ReportBugButton({ toggleCollapse, isCollapsed }) {
  let navigate = useNavigate();
  return (
    <div className="flex mb-2">
      {isCollapsed ? (
        <div className="block mx-auto mt-3">
          <button
            className="group flex items-center ml-[0.15rem] p-2 text-xs text-custom-gray-2 bg-gray-800 rounded-md duration-300"
            title="Report a bug/suggestion"
            onClick={() => navigate("/report-bug-or-suggestion")}
          >
            <GiSpottedBug
              className="transition-transform ease-in-out duration-300 group-hover:text-red-500 group-hover:rotate-6"
              size="20"
            />
            {!isCollapsed && "Report Bug / Suggestion"}
          </button>
          <button onClick={toggleCollapse} className="p-3 focus:outline-none">
            <FiChevronRight
              size="20"
              className="text-custom-gray-2"
              title="Open navigation menu"
            />
          </button>
        </div>
      ) : (
        <>
          <button
            className="group flex items-center pr-2 ml-2 text-xs text-custom-gray-2 bg-gray-800 rounded-md duration-300"
            onClick={() => navigate("/report-bug-or-suggestion")}
          >
            <GiSpottedBug
              className="mx-2 transition-transform ease-in-out duration-300 group-hover:text-red-500 group-hover:rotate-6"
              size="20"
            />
            {!isCollapsed && "Report Bug / Suggestion"}
          </button>
          <button
            onClick={toggleCollapse}
            className="ml-auto p-3 focus:outline-none"
          >
            <FiChevronLeft
              size="20"
              className="text-custom-gray-2"
              title="Close navigation menu"
            />
          </button>
        </>
      )}
    </div>
  );
}

export default ReportBugButton;
