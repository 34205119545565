import {
  AiOutlineStar,
  AiFillStar,
  AiOutlineEdit,
  AiOutlineDelete,
} from "react-icons/ai";
import { format } from "date-fns";
import { db } from "../../firebase";
import AddContact from "./AddContact";
import { FiArrowLeft, FiX, FiCheck, FiTrash } from "react-icons/fi";
import { useState, useEffect, useContext, useRef } from "react";
import { AuthContext } from "../../context/AuthContext";
import { MdArchive, MdOutlineShare, MdUnarchive } from "react-icons/md";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";
import { Link } from "react-router-dom";

function ExpandedContactView({
  selectedContact,
  isAddingContact,
  onContactArchived,
  setIsAddingContact,
  handleNavigateHome,
}) {
  const contactDeletionModalRef = useRef(null);
  const { currentUser } = useContext(AuthContext);
  const dbName = "contacts-" + currentUser.email;
  const [localContact, setLocalContact] = useState(selectedContact);
  const [contactDeletionModal, setContactDeletionModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContact, setEditedContact] = useState(localContact);

  // Update local contact state whenever selectedContact prop changes
  useEffect(() => {
    setLocalContact(selectedContact);
    setEditedContact(selectedContact);
  }, [selectedContact]);

  // Handle clicks outside contact deletion modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        contactDeletionModalRef.current &&
        !contactDeletionModalRef.current.contains(event.target)
      ) {
        setContactDeletionModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setContactDeletionModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Function to convert retrieved timestamp to formatted date string
  const formatDate = (timestamp) => {
    if (!timestamp) return "-";
    const date = new Date(
      timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000
    );
    return format(date, "PP");
  };

  // Function to star the selected contact
  const handleContactStarring = async () => {
    const updateStarredStatus = !localContact.starred;
    await updateDoc(doc(db, dbName, localContact.id), {
      starred: updateStarredStatus,
    });
    setLocalContact((prevContact) => ({
      ...prevContact,
      starred: updateStarredStatus,
    }));
  };

  // Function to archive the selected contact
  const handleContactArchiving = async () => {
    await updateDoc(doc(db, dbName, localContact.id), {
      archived: true,
    });
    onContactArchived(localContact.id);
  };

  // Function to archive the selected contact
  const handleContactUnarchiving = async () => {
    await updateDoc(doc(db, dbName, localContact.id), {
      archived: false,
    });
    onContactArchived(localContact.id);
  };

  // Handle contact deletion
  const handleContactDeletion = async () => {
    setContactDeletionModal(false);
    await deleteDoc(doc(db, dbName, localContact.id));
    onContactArchived(localContact.id);
  };

  // Save the changes made to the contact
  const handleSave = async () => {
    await updateDoc(doc(db, dbName, localContact.id), editedContact);
    setLocalContact(editedContact);
    setIsEditing(false);
  };

  // Handle changes to the inputs
  const handleChange = (field, value) => {
    setEditedContact((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  return (
    <div className="flex-1 h-[calc(100vh-1.8rem)] p-4 border border-gray-200 relative">
      {isAddingContact ? (
        <AddContact toggle={setIsAddingContact} />
      ) : localContact ? (
        <>
          <button onClick={handleNavigateHome}>
            <FiArrowLeft
              className="mt-2 ml-2 mb-5 cursor-pointer rounded-full text-gray-600"
              size="20"
              title="Go back"
            />
          </button>
          <div className="flex items-center ml-4 mb-4">
            <img
              src="https://via.placeholder.com/150"
              alt="Profile"
              className="rounded-full mr-8"
            />
            <h2 className="text-3xl font-medium">
              {localContact.firstName + " " + localContact.lastName}
            </h2>

            {/* Option Menu */}
            <div className="flex absolute top-4 right-6 space-x-6">
              <div
                className="text-gray-600 cursor-pointer pt-2"
                onClick={handleContactStarring}
              >
                {localContact.starred ? (
                  <AiFillStar
                    size={23}
                    className="text-yellow-400"
                    title="Unstar"
                  />
                ) : (
                  <AiOutlineStar size={23} title="Star" />
                )}
              </div>
              <div className="text-gray-600 font-medium cursor-pointer pt-2">
                <MdOutlineShare size={23} title="Share contact" />
              </div>
              <div className="text-gray-600 cursor-pointer pt-2">
                {localContact.archived ? (
                  <MdUnarchive
                    size={23}
                    title="Unarchive"
                    onClick={handleContactUnarchiving}
                  />
                ) : (
                  <MdArchive
                    size={23}
                    title="Archive"
                    onClick={handleContactArchiving}
                  />
                )}
              </div>
              <div
                className="text-gray-600 hover:text-red-600 cursor-pointer pt-2"
                onClick={() => setContactDeletionModal(true)}
              >
                <AiOutlineDelete size={23} title="Delete" />
              </div>
            </div>
          </div>

          {/* Contact Info Card */}
          <div className="bg-white p-6 mx-4 mt-6 rounded-xl border border-gray-200">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-xl font-semibold text-gray-600">
                Contact Info
              </h3>
              {isEditing ? (
                <div className="flex ml-auto -mt-6 -mr-2 space-x-3">
                  <FiCheck
                    size={18}
                    className="text-green-600 cursor-pointer"
                    onClick={handleSave}
                    title="Save changes"
                  />
                  <FiX
                    size={18}
                    className="text-gray-600 cursor-pointer"
                    onClick={() => setIsEditing(false)}
                    title="Cancel editing"
                  />
                </div>
              ) : (
                <AiOutlineEdit
                  className="text-gray-500 hover:text-gray-700 -mt-6 -mr-2 transition-colors cursor-pointer"
                  size={18}
                  title="Edit contact"
                  onClick={() => setIsEditing(true)}
                />
              )}
            </div>
            <ul className="space-y-2">
              {/* First name */}
              {localContact.firstName && (
                <li className="flex items-center text-gray-600">
                  {isEditing ? (
                    <>
                      <span className="w-24 font-semibold">First name:</span>
                      <input
                        type="text"
                        className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={editedContact.firstName}
                        onChange={(e) =>
                          handleChange("firstName", e.target.value)
                        }
                      />
                    </>
                  ) : null}
                </li>
              )}
              {/* Last name */}
              {localContact.lastName && (
                <li className="flex items-center text-gray-600">
                  {isEditing ? (
                    <>
                      <span className="w-24 font-semibold">Last name:</span>
                      <input
                        type="text"
                        className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                        value={editedContact.lastName}
                        onChange={(e) =>
                          handleChange("lastName", e.target.value)
                        }
                      />
                    </>
                  ) : null}
                </li>
              )}
              {/* Phone */}
              {localContact.phone && (
                <li className="flex items-center text-gray-600">
                  <span className="w-24 font-semibold">Phone:</span>
                  {isEditing ? (
                    <input
                      type="text"
                      className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={editedContact.phone}
                      onChange={(e) => handleChange("phone", e.target.value)}
                    />
                  ) : (
                    <span className="flex-1">{localContact.phone}</span>
                  )}
                </li>
              )}
              {/* Email */}
              {localContact.email && (
                <li className="flex items-center text-gray-600">
                  <span className="w-24 font-semibold">Email:</span>
                  {isEditing ? (
                    <input
                      type="email"
                      className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={editedContact.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                  ) : (
                    <span className="flex-1">{localContact.email}</span>
                  )}
                </li>
              )}
              {/* Address */}
              {localContact.address && (
                <li className="flex items-center text-gray-600">
                  <span className="w-24 font-semibold">Address:</span>
                  {isEditing ? (
                    <input
                      type="text"
                      className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={editedContact.address}
                      onChange={(e) => handleChange("address", e.target.value)}
                    />
                  ) : (
                    <span className="flex-1">{localContact.address}</span>
                  )}
                </li>
              )}
              {/* Birthday */}
              {localContact.birthday && (
                <li className="flex items-center text-gray-600">
                  <span className="w-24 font-semibold">Birthday:</span>
                  {isEditing ? (
                    <input
                      type="date"
                      className="flex-1 p-2 border border-gray-300 rounded-lg bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500"
                      value={formatDate(editedContact.birthday)}
                      onChange={(e) => handleChange("birthday", e.target.value)}
                    />
                  ) : (
                    <span className="flex-1">
                      {formatDate(localContact.birthday)}
                    </span>
                  )}
                </li>
              )}
            </ul>
          </div>
        </>
      ) : (
        // Resting info page
        <div className="flex justify-center items-center min-h-[calc(100vh-2rem)]">
          <div className="text-center">
            <h1 className="text-4xl font-semibold text-gray-800 mb-8">
              Connections, secured
            </h1>
            <ul className="space-y-4 text-lg">
              <li>
                <Link to="/settings/starconnect" className="text-blue-500">
                  Manage Settings
                </Link>
              </li>
              <li>
                <Link to="/starconnect/archive" className="text-blue-500">
                  See Archive
                </Link>
              </li>
              <li>
                <Link to="/documentation/starconnect" className="text-blue-500">
                  See Docs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* Contact Deletion Modal */}
      {contactDeletionModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%] space-y-3"
            ref={contactDeletionModalRef}
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Contact
              </h2>
              <FiX
                size={23}
                className="text-lg text-gray-600 cursor-pointer"
                onClick={() => setContactDeletionModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <div className="flex flex-col items-center">
              <p className="text-center text-gray-700 px-1 mb-2">
                Are you sure you want to delete this contact? This action{" "}
                <span className="font-semibold">cannot be undone.</span>
              </p>
              <button
                className="flex text-md font-medium p-2 w-full md:w-auto rounded text-red-500 hover:bg-red-500 hover:text-white ease-in-out duration-200"
                onClick={handleContactDeletion}
              >
                <FiTrash className="mt-[0.2rem] mr-1" />
                Delete Contact
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ExpandedContactView;
