import React, { useContext, useState } from "react";
import emailjs from "emailjs-com";
import DOMPurify from "dompurify";
import { GiSpottedBug } from "react-icons/gi";
import { FaPaperPlane } from "react-icons/fa";
import { AuthContext } from "../context/AuthContext";
import ProgressBar from "react-bootstrap/ProgressBar"; // Import ProgressBar component

function ReportBug() {
  const { currentUser } = useContext(AuthContext);
  const [issueType, setIssueType] = useState("");
  const [issueArea, setIssueArea] = useState("");
  const [otherArea, setOtherArea] = useState("");
  const [description, setDescription] = useState("");
  const [isSending, setIsSending] = useState(false); // State to track sending progress
  const [progress, setProgress] = useState(0); // State for progress bar

  // Function to handle email sending
  const sendEmail = async (e) => {
    e.preventDefault();

    // Validation to ensure required fields are filled
    if (!issueType || !issueArea || !description) {
      alert("Please fill out all required fields.");
      return;
    }

    setIsSending(true);

    // Sanitize inputs to for enhanced security
    const sanitizedIssueType = DOMPurify.sanitize(issueType);
    const sanitizedIssueArea = DOMPurify.sanitize(issueArea);
    const sanitizedOtherArea = DOMPurify.sanitize(otherArea);
    const sanitizedDescription = DOMPurify.sanitize(description);

    // Prepare parameters for the email template
    const templateParams = {
      sender_email: currentUser.email,
      issue_type: sanitizedIssueType,
      issue_area:
        sanitizedIssueArea !== "other"
          ? sanitizedIssueArea
          : sanitizedOtherArea,
      description: sanitizedDescription,
    };

    try {
      // Simulate sending process with progress updates (replace with actual email sending logic)
      for (let i = 0; i <= 100; i++) {
        await new Promise((resolve) => setTimeout(resolve, 20));
        setProgress(i);
      }

      // Send email using emailjs
      await emailjs.send(
        process.env.REACT_APP_EMAILJS_SERVICE_ID,
        process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
        templateParams,
        process.env.REACT_APP_EMAILJS_USER_ID
      );
      alert("Email successfully sent!");
    } catch (error) {
      console.error("Error sending email");
      alert("Failed to send email. Please try again later.");
    } finally {
      setIsSending(false);
      setProgress(0);
      resetForm();
    }
  };

  // Reset form fields
  const resetForm = () => {
    setIssueType("");
    setIssueArea("");
    setOtherArea("");
    setDescription("");
  };

  return (
    <div className="container mt-8 p-6">
      <div className="max-w-2xl mx-auto border rounded-lg p-6 shadow-lg">
        <div className="flex items-center mb-4">
          <GiSpottedBug size={24} className="mr-2" />
          <h1 className="text-xl font-semibold">
            Report a Bug/Issue/Problem or Suggestion
          </h1>
        </div>
        <form onSubmit={sendEmail}>
          <div className="mb-4">
            <span className="block mb-2 font-medium">Choose Type:</span>
            <select
              className="w-full p-2 border rounded"
              value={issueType}
              onChange={(e) => setIssueType(e.target.value)}
            >
              <option value="" disabled>
                Select an Issue
              </option>
              <option value="bug">Bug/Issue/Problem</option>
              <option value="suggestion">Suggestion</option>
            </select>
          </div>
          <div className="mb-4">
            <span className="block mb-2 font-medium">
              Choose Problem/Suggestion Area:
            </span>
            <select
              className="w-full p-2 border rounded"
              value={issueArea}
              onChange={(e) => setIssueArea(e.target.value)}
            >
              <option value="" disabled>
                Select Problem/Suggestion Area
              </option>
              <option value="star-chat">Star Chat</option>
              <option value="star-note">Star Note</option>
              <option value="star-connect">Star Connect</option>
              <option value="star-safe">Star Safe</option>
              <option value="ui-ux">Overall UI/UX</option>
              <option value="other">Other</option>
            </select>
            {issueArea === "other" && (
              <input
                type="text"
                className="w-full p-2 border rounded mt-2"
                placeholder="Enter name of issue area here..."
                value={otherArea}
                onChange={(e) => setOtherArea(e.target.value)}
              />
            )}
          </div>
          <div className="mb-4">
            <span className="block mb-2 font-medium">
              Describe your issue here:
            </span>
            <textarea
              className="w-full p-2 border rounded"
              placeholder="Describe your issue here."
              rows="4"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            ></textarea>
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-500 text-white p-2 rounded flex items-center justify-center"
            disabled={isSending} // Disable button while sending
          >
            {isSending ? (
              <>
                <ProgressBar now={progress} label={`${progress}%`} />
                Sending...
              </>
            ) : (
              <>
                <FaPaperPlane className="mr-2" />
                {progress === 100 ? "Sent!" : "Submit"}
              </>
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

export default ReportBug;
