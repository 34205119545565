import React from "react";

function StarChatInfo() {
  return (
    <div className="mx-auto p-6">
      {/* Page Title */}
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Using Star Chat on Starrvault
      </h1>

      {/* Introduction */}
      <p className="text-gray-700 mb-8">
        Star Chat is a powerful communication tool integrated within Starrvault.
        Whether you want to send messages, make voice/video calls, or manage
        your contacts, Star Chat has got you covered. In this guide, we'll walk
        you through how to make the most of the Star Chat features.
      </p>

      {/* Important Requirement Section */}
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
        <h2 className="text-2xl font-semibold text-yellow-800 mb-2">
          Important: Contact Saving Requirement
        </h2>
        <p className="text-yellow-700">
          In order to chat with someone on Star Chat, you must have their
          contact saved in <strong>Star Connect</strong>. Additionally, the
          person you're trying to chat with must also have signed up on
          Starrvault using the same email ID that you have saved in your
          contacts.
        </p>
        <p className="text-yellow-700 mt-2">
          If the user hasn't signed up yet, you won't be able to initiate a chat
          with them on Star Chat. Make sure both parties have active accounts
          with matching email addresses.
        </p>
      </div>

      {/* How to Send Messages */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Sending Messages
      </h2>

      <p className="text-gray-700 mb-4">
        Communicating with your contacts via Star Chat is simple. Follow these
        steps to send a message:
      </p>

      <div className="p-2 ml-6 rounded-xl border border-gray-300">
        <ul className="text-gray-700 list-inside space-y-2">
          <li>
            <i>Step 1:</i> Open the Star Chat interface from your dashboard.
          </li>
          <li>
            <i>Step 2:</i> Select the contact you want to chat with from your
            chat list.
          </li>
          <li>
            <i>Step 3:</i> Type your message in the chat box and hit Enter or
            click the "Send" button.
          </li>
        </ul>
      </div>

      {/* How to Make Voice/Video Calls */}
      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
        Making Voice and Video Calls
      </h2>

      <p className="text-gray-700 mb-4">
        Star Chat allows you to initiate voice and video calls with your
        contacts. Here's how to start a call:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-inside">
        <div className="p-2 rounded-xl border border-gray-300">
          <li className="mb-2">
            <i>Step 1:</i> Open a chat with the contact you want to call.
          </li>
          <li className="mb-2">
            <i>Step 2:</i> Click the "Call" icon at the top-right of the chat
            window.
          </li>
          <li>
            <i>Step 3:</i> Choose between a voice call or video call, depending
            on your preference.
          </li>
        </div>
      </ul>

      {/* Managing Contacts */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Managing Your Contacts
      </h2>

      <p className="text-gray-700 mb-4">
        Adding and managing contacts in Star Chat helps you stay connected with
        the people you care about. Here's how to manage your contacts:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-inside">
        <div className="p-2 rounded-xl border border-gray-300">
          <li className="mb-2">
            <i>Step 1:</i> Navigate to the "Contacts" section in Star Chat.
          </li>
          <li className="mb-2">
            <i>Step 2:</i> Use the "Add Contact" button to add new connections.
          </li>
          <li>
            <i>Step 3:</i> You can also remove or edit contacts by selecting
            their profiles and choosing the relevant options.
          </li>
        </div>
      </ul>

      {/* Important Requirement Section */}
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8">
        <h2 className="text-2xl font-semibold text-yellow-800 mb-2">
          Managing Archived Chats
        </h2>
        <p className="text-yellow-700">
          There are two types of archives that you can create in Star Chat. The
          first one is a <b>normal archive</b> and the second one is a archived{" "}
          <b>read-only copy</b> of the chat. You can access the archive by
          clicking on 'Star Chat' text present in the top-left, this will take
          you to the Star Chat homepage. Once on the homepage, you can access
          the archive by clicking on the 'See Archive' text present in the right
          window. Both of them have vastly different applications. These are the
          applications of both and reasons on why you might consider creating
          them.
        </p>
        <ul className="text-yellow-700 space-y-2 ml-8 mt-1">
          <li>
            1.{" "}
            <b className="text-yellow-700 underline underline-offset-2">
              Normal Archive:
            </b>{" "}
            A normal archive is useful for tidying up your chat space without
            permanently removing conversations. By archiving a chat, you can
            declutter your main chat view, prevent accidental messages to
            certain contacts, or simply organize conversations you don't need
            immediate access to. This helps maintain a clean and focused chat
            interface, keeping important messages accessible while allowing less
            active chats to stay safely stored.
          </li>
          <li>
            2.{" "}
            <b className="text-yellow-700 underline underline-offset-2">
              Read-only Archive:
            </b>{" "}
            Creating an archived read-only chat copy ensures secure, uneditable
            storage, ideal for legal compliance, sensitive information
            retention, or professional accountability. It allows users to
            preserve essential conversations without risking accidental edits,
            offering a reliable reference for audits, client interactions, or
            personal memories. This feature is especially valuable when original
            content integrity is crucial, providing peace of mind that important
            exchanges remain intact and accessible.{" "}
            <i>
              Read-only copies of a conversation can be recognized from the eye
              icon present in the top-right of the chat bar.
            </i>
          </li>
        </ul>
      </div>

      {/* Archive, Pinning, Starting a Chat, and Deleting Chats */}
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-8">
        <h2 className="text-2xl font-semibold text-blue-800 mb-2">
          Archiving, Pinning, Starting, and Deleting Chats
        </h2>

        <p className="text-blue-700 mb-4">
          Star Chat allows you to organize your conversations efficiently. Below
          are the steps for archiving, pinning, starting, and deleting chats.
        </p>

        {/* Starting a new chat */}
        <div className="p-3 mt-4 rounded-2xl border-2 border-blue-100">
          <h3 className="text-xl font-semibold text-blue-700 mb-1">
            Starting a New Chat
          </h3>
          <ul className="text-blue-700 ml-4">
            <li>
              1. Click the plus icon (+) in the search bar on the Sidebar to
              start a new chat.
            </li>

            <li>
              2. Enter the email address of the person you wish to contact, and
              their name will appear below the search bar.
            </li>

            <li>
              3. After selecting the person, the chat will appear in the
              Sidebar. (Note: It may initially appear as "Unknown User,"
              requiring a refresh to display the correct name.)
            </li>
          </ul>
        </div>

        {/* Pinning Chats */}
        <div className="p-3 mt-4 rounded-2xl border-2 border-blue-100">
          <h3 className="text-xl font-semibold text-blue-700 mb-1">
            Pinning Chats
          </h3>
          <p className="text-blue-700 mb-2">
            You can pin chats to keep important conversations at the top of your
            chat list. Pinned chats stay at the top for easy access, making sure
            you don't lose track of crucial conversations. <br />
          </p>
          <strong className="text-blue-700">How to pin a chat?</strong>
          <ul className="list-disc ml-8 text-blue-700">
            <li>Click on the more icon present in the top-right.</li>
            <li>
              After the menu with multiple options opens, click on the first
              option, 'Pin Chat'.
            </li>
            <li>
              The chat should now be pinned and appear at the top for easier
              access. When you want to unpin a chat, click the same option from
              the menu, and it will be unpinned.
            </li>
          </ul>
        </div>

        {/* Archiving Chats */}
        <div className="p-3 mt-4 rounded-2xl border-2 border-blue-100">
          <h3 className="text-xl font-semibold text-blue-700 mb-2">
            Archiving Chats
          </h3>
          <p className="text-blue-700">
            <ul className="list-disc ml-8">
              <li>
                <strong>Archive a copy:</strong> Archiving a copy will store a
                read-only version of the conversation in your account for
                reference.
              </li>
              <li>
                <strong>Archive:</strong> Archiving will move the conversation
                to archived chats, where you can continue chatting by visiting
                the archive.
              </li>
            </ul>
          </p>
        </div>

        {/* Deleting Chats */}
        <div className="p-4 mt-4 rounded-2xl border-2 border-blue-100">
          <h3 className="text-xl font-semibold text-blue-700 mb-1">
            Deleting Chats
          </h3>
          <p className="text-blue-700">
            To delete a chat, navigate to the chat list, right-click or tap on
            the chat you want to delete, and choose the delete option. Deleting
            a chat will remove it from the chat list permanently, and it cannot
            be recovered.
          </p>
        </div>
      </div>

      {/* Troubleshooting */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Troubleshooting Issues in Star Chat
      </h2>

      <p className="text-gray-700 mb-8">
        If you encounter any issues while using Star Chat, such as connection
        problems during calls or trouble sending messages, here are a few things
        you can try:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-disc list-inside">
        <li className="mb-2">
          <strong>Check your internet connection:</strong> Ensure you have a
          stable internet connection to avoid connectivity issues.
        </li>
        <li className="mb-2">
          <strong>Update your browser:</strong> Make sure you're using the
          latest version of your browser, as Star Chat works best with updated
          technology.
        </li>
        <li className="mb-2">
          <strong>Clear your browser cache:</strong> If the chat is lagging or
          not displaying correctly, clearing your cache may resolve the problem.
        </li>
      </ul>

      {/* Contact Support */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Need Further Assistance?
      </h2>

      <p className="text-gray-700 mb-8">
        If you're still experiencing issues after troubleshooting, feel free to
        reach out to the Starrvault support team at{" "}
        <strong>starrvault24@gmail.com</strong>. Include details of the problem,
        and our team will get back to you as soon as possible.
      </p>
    </div>
  );
}

export default StarChatInfo;
