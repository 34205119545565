import {
  AiOutlineEdit,
  AiOutlineInbox,
  AiOutlineDelete,
  AiOutlineFileText,
} from "react-icons/ai";
import { Link } from "react-router-dom";
import { FiCheck, FiX } from "react-icons/fi";
import { MdLabelOutline } from "react-icons/md";
import React, { useState, useEffect, useCallback } from "react";
import { FaHashtag, FaChevronRight, FaStar } from "react-icons/fa";

function Sidebar({
  labels,
  handleAddLabel,
  handleEditLabel,
  editingLabelIndex,
  editingLabelValue,
  handleDeleteLabel,
  setEditingLabelValue,
  setEditingLabelIndex,
}) {
  const [labelInput, setLabelInput] = useState("");
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);

  // Function to CLOSE modal
  const handleCloseModal = useCallback(() => {
    setIsLabelModalOpen(false);
    setEditingLabelIndex(null);
    setEditingLabelValue("");
  }, [setEditingLabelIndex, setEditingLabelValue]);

  useEffect(() => {
    // Function to handle 'Escape' key press
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        handleCloseModal();
      }
    };
    window.addEventListener("keydown", handleEsc);
    return () => {
      window.removeEventListener("keydown", handleEsc);
    };
  }, [handleCloseModal]);

  // Function to save the changes made to label
  const handleSaveEdit = () => {
    if (editingLabelValue.trim() !== "") {
      handleEditLabel(editingLabelIndex, editingLabelValue);
    }
  };

  // Function to cancel edit of label
  const handleCancelEdit = () => {
    setEditingLabelIndex(null);
    setEditingLabelValue("");
  };

  const handleAddLabelClick = () => {
    if (labelInput.trim() !== "") {
      handleAddLabel(labelInput.trim());
      setLabelInput("");
    }
  };

  return (
    <div className="w-72 p-4 bg-gray-100 rounded-l-lg">
      <div className="flex items-center justify-between ml-2 mb-4">
        <Link to="/starnote">
          <h2 className="text-xl font-bold text-gray-800">Star Note</h2>
        </Link>
      </div>
      <div className="w-72 -ml-4 border-t border-gray-300 my-2"></div>

      {/* Navigation */}
      <ul className="mx-auto space-y-1">
        <Link to="/starnote/notes">
          <li className="flex items-center text-gray-800 hover:bg-gray-200 p-2 rounded-lg cursor-pointer transition duration-200">
            <AiOutlineFileText className="text-lg mr-2" />
            <span className="text-md font-medium">Notes</span>
          </li>
        </Link>
        <Link to="/starnote/archive">
          <li className="flex items-center text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <AiOutlineInbox className="text-lg mr-2" />
            <span className="text-md font-medium">Archive</span>
          </li>
        </Link>
        <Link to="/starnote/bin">
          <li className="flex items-center text-gray-800 hover:bg-gray-200 p-2 rounded cursor-pointer transition duration-200">
            <AiOutlineDelete className="text-lg mr-2" />
            <span className="text-md font-medium">Bin</span>
          </li>
        </Link>
      </ul>
      <div className="w-72 -ml-4 border-t border-gray-300 my-2" />

      {/* Labels display */}
      <ul>
        <li className="flex items-center justify-between text-gray-800 bg-gray-200 p-2 rounded mb-1">
          <div className="flex items-center">
            <MdLabelOutline className="text-lg mr-2" />
            <span className="text-md font-medium">Labels</span>
          </div>
          <button
            className="flex items-center text-sm font-medium text-blue-600 hover:text-blue-800 ml-auto"
            onClick={() => setIsLabelModalOpen(true)}
          >
            Add Labels
          </button>
        </li>
        <Link to="/starnote/labels/starred">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 ml-3 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FaStar className="text-sm text-yellow-300 mr-2" size={15} />
              <span className="text-md font-medium">Starred</span>
            </div>
            <FaChevronRight className="text-sm mr-2" />
          </li>
        </Link>
        {labels.map((label, index) => (
          <Link to={`/starnote/labels/${label.labelName}`} key={index}>
            <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-2 ml-3 rounded cursor-pointer transition duration-200">
              <div className="flex items-center">
                <FaHashtag className="text-sm mr-2" size={15} />
                <span className="text-md font-medium">
                  {label.labelName || "Untitled"}
                </span>
              </div>
              <FaChevronRight className="text-sm mr-2" />
            </li>
          </Link>
        ))}
      </ul>

      {/* Labels modal */}
      {isLabelModalOpen && (
        <div
          id="modal-overlay"
          className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
          onClick={handleCloseModal}
        >
          <div
            className="bg-white p-4 rounded shadow-lg"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-2">
              <h2 className="text-lg font-semibold text-gray-800">
                Edit Labels
              </h2>
              <FiX
                id="close-button"
                className="text-lg text-gray-700 cursor-pointer"
                onClick={handleCloseModal}
                title="Close"
              />
            </div>
            <div className="-mx-4 mt-2 mb-4 border border-t-gray-300" />
            <div className="flex items-center mb-3">
              <input
                type="text"
                placeholder="Create new label"
                value={labelInput}
                onChange={(e) => setLabelInput(e.target.value)}
                className="border text-md p-1 flex-grow mr-2"
              />
              <button
                onClick={handleAddLabelClick}
                className="text-green-400 hover:text-green-500"
              >
                <FiCheck size={18} title="Save label" />
              </button>
            </div>
            <ul>
              {labels
                .filter((label) =>
                  (label.labelName || "").includes(labelInput || "")
                )
                .map((label, index) => (
                  <li key={index} className="flex items-center p-2">
                    <FaHashtag className="mr-1 text-sm" />
                    {editingLabelIndex === index ? (
                      <input
                        type="text"
                        value={editingLabelValue}
                        onChange={(e) => setEditingLabelValue(e.target.value)}
                        className="border p-1 flex-grow w-14 mr-2"
                      />
                    ) : (
                      <span>{label.labelName || "Untitled"}</span>
                    )}
                    {editingLabelIndex === index ? (
                      <>
                        <button
                          onClick={handleSaveEdit}
                          title={`Set label to ${editingLabelValue}`}
                          className="ml-auto text-green-400 hover:text-green-500"
                          disabled={editingLabelValue.trim() === ""}
                        >
                          <FiCheck size={18} />
                        </button>
                        <button
                          onClick={handleCancelEdit}
                          title="Cancel editing"
                          className="ml-2 text-red-400 hover:text-red-500"
                        >
                          <FiX size={18} />
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          onClick={() => {
                            setEditingLabelIndex(index);
                            setEditingLabelValue(label.labelName);
                          }}
                          className="ml-auto text-gray-400 hover:text-gray-500 duration-200 ease-in-out"
                          title="Edit label"
                        >
                          <AiOutlineEdit size={18} />
                        </button>
                        <button
                          onClick={() => handleDeleteLabel(index)}
                          className="ml-2 text-gray-400 hover:text-red-500 duration-200 ease-in-out"
                          title="Delete label"
                        >
                          <AiOutlineDelete size={18} />
                        </button>
                      </>
                    )}
                  </li>
                ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}

export default Sidebar;
