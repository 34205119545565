import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import Note from "../components/Note";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useLabels } from "../../context/LabelsContext";

function StarNoteBin() {
  const { labels } = useLabels();
  const { currentUser } = useContext(AuthContext);
  const [starredNotes, setStarredNotes] = useState();
  const collectionTitle = `notes-${currentUser.email}`;

  // Fetch all notes saved by the user where noteStatus is 'starred'
  useEffect(() => {
    const fetchStarredStatus = async () => {
      if (currentUser) {
        const dnq = query(
          collection(db, collectionTitle),
          where("noteStatus", "==", "starred")
        );
        const querySnapshot = await getDocs(dnq);
        setStarredNotes(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    };
    fetchStarredStatus();
  }, [collectionTitle, currentUser]);

  // Function to handle the starring of a note
  const handleNoteStarring = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    const noteDoc = await getDoc(noteRef);
    if (!noteDoc.exists()) {
      console.log("No such document!");
      return;
    }
    const currentStatus = noteDoc.data().noteStatus;

    const newStatus = currentStatus === "active" ? "starred" : "active";
    await updateDoc(noteRef, {
      noteStatus: newStatus,
    });

    setStarredNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId ? { ...note, noteStatus: newStatus } : note
      )
    );
  };

  // Function to handle the deletion of notes
  const handleNoteDelete = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { noteStatus: "binned" });
    setStarredNotes((prevNotes) =>
      prevNotes.filter((note) => note.id !== noteId)
    );
  };

  // Function to handle copying of the note content
  const handleNoteCopy = (noteContent) => {
    navigator.clipboard
      .writeText(noteContent)
      .then(() => {
        alert("Note content copied to clipboad");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Function to handle the archiving of a note
  const handleNoteArchiving = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, {
      noteStatus: "archived",
    });

    setStarredNotes((prevNotes) =>
      prevNotes.filter((note) => note.id !== noteId)
    );
  };

  // Function to handle saving edits to a note
  const handleSaveEdit = async (noteId, updatedNote) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, updatedNote);

    setStarredNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId ? { ...note, ...updatedNote } : note
      )
    );
  };

  // Function to assign labels to a note
  const handleLabelAssigning = async (noteId, label) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { label });
    setStarredNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === noteId ? { ...note, label } : note))
    );
  };

  return (
    <div className="relative flex-1 px-4 pt-3 bg-white">
      <div className="flex items-center">
        <h2 className="text-xl ml-2 mt-2 font-medium">Starred Notes</h2>
      </div>
      <Note
        labels={labels}
        showLabels={true}
        userNotes={starredNotes}
        onNoteCopy={handleNoteCopy}
        onNoteSaveEdit={handleSaveEdit}
        onNoteDelete={handleNoteDelete}
        onNoteStarring={handleNoteStarring}
        onNoteArchiving={handleNoteArchiving}
        onLabelAssigning={handleLabelAssigning}
      />
    </div>
  );
}

export default StarNoteBin;
