import { auth } from "../firebase";
import { useState, useEffect } from "react";
import { onAuthStateChanged } from "firebase/auth";
import LoginRegisterTile from "../appComponents/LoginRegisterTile";
import LandingPage from "./LandingPage";

function Home(isNavOpen) {
  const [userState, setUserState] = useState(null);

  // Check for changes in user state
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUserState(!!user);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div>
      {userState ? (
        <div className={` ${isNavOpen ? "ml-10" : "ml-70"}`}>Home</div>
      ) : (
        <div className="ml-0">
          <LoginRegisterTile />
          <LandingPage />
        </div>
      )}
    </div>
  );
}

export default Home;
