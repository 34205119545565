import React from "react";

function StarConnectInfo() {
  return (
    <div className="mx-auto p-6">
      {/* Page Title */}
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Using Star Connect on Starrvault
      </h1>

      {/* Introduction */}
      <p className="text-gray-700 mb-8">
        Star Connect is an efficient contact management tool integrated within
        Starrvault. It allows you to save, organize, and manage your important
        connections in one place, making it easier to stay in touch and
        communicate through Star Chat. This guide will show you how to make the
        most of Star Connect's features.
      </p>

      {/* Adding Contacts */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Adding Contacts
      </h2>

      <p className="text-gray-700 mb-4">
        Star Connect makes it simple to add contacts. Here's how to add new
        connections:
      </p>

      <div className="p-2 ml-6 rounded-xl border border-gray-300">
        <ul className="text-gray-700 list-inside space-y-2">
          <li>
            <i>Step 1:</i> Open the Star Connect interface from your Starrvault
            dashboard.
          </li>
          <li>
            <i>Step 2:</i> Click on the "+" icon present next to the search bar.
          </li>
          <li>
            <i>Step 3:</i> Enter the contact's details, such as their name and
            email address, then save the contact.
          </li>
        </ul>
      </div>

      {/* Editing and Deleting Contacts */}
      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
        Editing and Deleting Contacts
      </h2>

      <p className="text-gray-700 mb-4">
        You can easily update or remove a contact's information at any time.
        Here's how:
      </p>

      <div className="p-2 ml-6 rounded-xl border border-gray-300">
        <ul className="text-gray-700 list-inside space-y-2">
          <li>
            <i>Step 1:</i> Select the contact you wish to edit or delete from
            the contact list.
          </li>
          <li>
            <i>Step 2:</i> Click the "Edit" button to update details or "Delete"
            to remove the contact permanently.
          </li>
        </ul>
      </div>

      {/* Contact Synchronization with Star Chat */}
      <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mb-8 mt-8">
        <h2 className="text-2xl font-semibold text-yellow-800 mb-2">
          Chatting with Contacts on Star Chat
        </h2>
        <p className="text-yellow-700">
          To initiate chats with a contact, ensure they are saved in Star
          Connect. The person you are trying to communicate with must also be a
          registered user on Starrvault using the same email ID you have saved
          in your contacts.
        </p>
      </div>

      {/* Organizing Contacts */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Organizing Contacts
      </h2>

      <p className="text-gray-700 mb-4">
        Star Connect offers various ways to organize your contacts for quick
        access. Here's how to utilize these organizational features:
      </p>

      <div className="p-2 ml-6 rounded-xl border border-gray-300">
        <ul className="text-gray-700 list-inside space-y-2">
          <li>
            <i>Step 1:</i> Use tags to categorize contacts, making it easier to
            locate them based on groups.
          </li>
          <li>
            <i>Step 2:</i> Sort contacts alphabetically or by most recently
            added for streamlined access.
          </li>
          <li>
            <i>Step 3:</i> Favorite certain contacts to keep them at the top of
            your contact list.
          </li>
        </ul>
      </div>

      {/* Importing and Exporting Contacts */}
      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
        Importing and Exporting Contacts
      </h2>

      <p className="text-gray-700 mb-4">
        Star Connect allows you to import contacts from external sources or
        export your existing contact list for backup or external use.
      </p>

      <div className="p-2 ml-6 rounded-xl border border-gray-300">
        <ul className="text-gray-700 list-inside space-y-2">
          <li>
            <i>Step 1:</i> Click on the "Import/Export" option in the Star
            Connect settings.
          </li>
          <li>
            <i>Step 2:</i> Follow the on-screen instructions to import contacts
            from a file or export them as a backup.
          </li>
        </ul>
      </div>

      {/* Troubleshooting */}
      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
        Troubleshooting Contact Issues
      </h2>

      <p className="text-gray-700 mb-8">
        If you encounter issues while managing contacts, such as synchronization
        problems, try these solutions:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-disc list-inside">
        <li className="mb-2">
          <strong>Check your internet connection:</strong> Ensure you're
          connected to the internet for real-time updates.
        </li>
        <li className="mb-2">
          <strong>Refresh the app:</strong> A quick refresh can help resolve
          display or sync issues.
        </li>
      </ul>

      {/* Contact Support */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Need Further Assistance?
      </h2>

      <p className="text-gray-700 mb-8">
        If you're still experiencing issues, contact Starrvault support at{" "}
        <strong>starrvault24@gmail.com</strong>.
      </p>
    </div>
  );
}

export default StarConnectInfo;
