import React, { useState } from "react";
import { Link } from "react-router-dom";

function StarChatSettings() {
  // State to track the current settings
  const [theme, setTheme] = useState("default");
  const [fontSize, setFontSize] = useState("medium");
  const [twoFactorEnabled, setTwoFactorEnabled] = useState(false);
  const [encryptionType, setEncryptionType] = useState("none");

  return (
    <div className="p-4 space-y-8">
      <h2 className="text-2xl font-semibold text-gray-800">
        Star Chat Settings
      </h2>

      {/* Custom Themes */}
      <div className="space-y-3 p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">Custom Themes</h3>
        <div className="flex">
          <select
            value={theme}
            onChange={(e) => setTheme(e.target.value)}
            className="w-full p-2 rounded-lg border outline-none border-gray-300 text-gray-800"
          >
            <option value="default">Default</option>
            <option value="dark">Dark</option>
            <option value="light">Light</option>
            <option value="custom">High Contrast</option>
            <option value="custom">Rose</option>
            <option value="custom">Sunset</option>
          </select>
          <button className="w-28 text-white bg-blue-500 rounded ml-4">
            Set theme
          </button>
        </div>
      </div>

      {/* Font Customization */}
      <div className="space-y-4 p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">
          Font Customization
        </h3>
        <div className="flex">
          <select
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
            className="w-full p-2 rounded-lg border border-gray-300"
          >
            <option value="small">Small</option>
            <option value="medium">Medium (recommended)</option>
            <option value="large">Large</option>
            <option value="x-large">Extra Large</option>
          </select>
          <button className=" text-white bg-blue-500 rounded px-3 ml-4">
            Save
          </button>
        </div>
      </div>

      {/* Two-factor Authentication */}
      <div className="flex items-center justify-between p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">
          Two-factor Authentication
        </h3>
        <div className="flex items-center space-x-4">
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="enabled"
              checked={twoFactorEnabled}
              onChange={() => setTwoFactorEnabled(true)}
              className="form-radio h-5 w-5 text-blue-600 dark:text-blue-400"
            />
            <span className="ml-2 text-gray-600 dark:text-gray-400">
              Enabled
            </span>
          </label>
          <label className="inline-flex items-center">
            <input
              type="radio"
              value="disabled"
              checked={!twoFactorEnabled}
              onChange={() => setTwoFactorEnabled(false)}
              className="form-radio h-5 w-5 text-blue-600 dark:text-blue-400"
            />
            <span className="ml-2 text-gray-600 dark:text-gray-400">
              Disabled
            </span>
          </label>
        </div>
      </div>

      {/* Blocked Contacts */}
      <div className="flex justify-between p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">Blocked Contacts</h3>
        <Link
          to="/settings"
          className="my-auto hover:underline hover:text-blue-500"
        >
          Manage blocked users
        </Link>
      </div>

      {/* Lock Chats */}
      <div className="flex items-center justify-between p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">Lock Chats</h3>
        <div className="flex">
          <button className="text-white bg-blue-500 ml-auto p-2 rounded">
            Manage Password
          </button>
          <button className="text-white bg-blue-500 ml-4 p-2 rounded">
            See Locked Chats
          </button>
        </div>
      </div>

      {/* Encryption Settings */}
      <div className="space-y-4  p-3 border border-gray-200 rounded">
        <h3 className="text-lg font-medium text-gray-700">Encryption Type</h3>
        <select
          value={encryptionType}
          onChange={(e) => setEncryptionType(e.target.value)}
          className="w-full p-3 rounded-lg border outline-none border-gray-300 text-gray-800"
        >
          <option value="none">None</option>
          <option value="base64">Base64 (Basic)</option>
          <option value="AES256">AES256 (Advanced)</option>
        </select>
      </div>
    </div>
  );
}

export default StarChatSettings;
