import React from "react";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { FiEdit, FiMessageSquare, FiUsers } from "react-icons/fi";

function SettingsSidebar() {
  return (
    <div className="w-44">
      <h2 className="text-custom-gray-1 text-2xl font-medium mb-4">Settings</h2>
      <ul className="space-y-2 -ml-2">
        <Link to="/settings/account" className="text-md font-medium">
          <li className="flex items-center text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
            <FaUser className="mr-2" />
            Account
          </li>
        </Link>
        <Link to="/settings/starchat" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiMessageSquare className="mr-2" />
              Star Chat
            </div>
          </li>
        </Link>

        <Link to="/settings/starnote" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiEdit className="mr-2" />
              Star Note
            </div>
          </li>
        </Link>

        <Link to="/settings/starconnect" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiUsers className="mr-2" />
              Star Connect
            </div>
          </li>
        </Link>

        {/* <Link to="/settings/starsafe" className="text-md font-medium">
          <li className="flex items-center justify-between text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
            <div className="flex items-center">
              <FiLock className="mr-2" />
              Star Safe
            </div>
          </li>
        </Link> */}
      </ul>
      <div className="flex flex-col h-[66vh]">
        <Link
          to="/documentation"
          className="text-sm mt-auto -ml-1 text-blue-500 hover:underline self-start"
        >
          See Docs
        </Link>
      </div>
    </div>
  );
}

export default SettingsSidebar;
