import {
  sendEmailVerification,
  createUserWithEmailAndPassword,
} from "firebase/auth";
import { useState } from "react";
import { db, auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { addDoc, collection } from "firebase/firestore";
import LoginRegisterTile from "../appComponents/LoginRegisterTile";

function Register() {
  let navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Password validation logic
  const validatePassword = (password) => {
    if (password.length < 8) {
      return "Password must be at least 8 characters long.";
    }
    if (/\s/.test(password)) {
      return "Password cannot contain spaces.";
    }
    return "";
  };

  // Trim whitespace
  const sanitizeInput = (input) => {
    return input.trim();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sanitize inputs
    const sanitizedEmail = sanitizeInput(email);
    const sanitizedUsername = sanitizeInput(username);
    const sanitizedPassword = sanitizeInput(password);

    const error = validatePassword(sanitizedPassword);
    if (error) {
      setPasswordError(error);
      return;
    }

    try {
      // Create the user
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        sanitizedEmail,
        sanitizedPassword
      );
      await sendEmailVerification(userCredential.user);
      // Add user data to db
      await addDoc(collection(db, "users"), {
        email: sanitizedEmail,
        username: sanitizedUsername,
        photoURL: "https://via.placeholder.com/40",
        noteLabels: [],
        uid: userCredential.user.uid,
      });
      alert(
        "A verification email has been sent to you. Please click on the link in the e-mail to verify"
      );
      navigate("/");
      window.location.reload(false);
    } catch (err) {
      alert("Error", err);
    }
  };

  return (
    <>
      <LoginRegisterTile />
      <div className="min-h-[93vh] flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="relative bg-white p-10 rounded-xl flex flex-col items-center space-y-6 w-full max-w-md shadow-2xl"
        >
          {/* Gradient Shadow */}
          <div
            className="absolute inset-0 rounded-xl"
            style={{
              zIndex: -1,
              background: "linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6)",
              filter: "blur(20px)",
            }}
          />
          <h3 className="text-3xl font-mrat font-semibold text-center tracking-tight">
            Register
          </h3>
          <div className="w-full">
            <label htmlFor="username" className="block text-gray-500 mb-1">
              Username
            </label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(sanitizeInput(e.target.value))}
              placeholder="Enter username"
              className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="w-full">
            <label htmlFor="email" className="block text-gray-500 mb-1">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(sanitizeInput(e.target.value))}
              placeholder="Enter email address"
              className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="w-full relative">
            <label htmlFor="password" className="block text-gray-500 mb-1">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => {
                setPassword(sanitizeInput(e.target.value));
                setPasswordError("");
              }}
              placeholder="Enter password"
              className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-11 flex items-center cursor-pointer text-gray-500"
            >
              {showPassword ? <LuEye /> : <LuEyeOff />}
            </div>
            {passwordError && (
              <p className="text-red-500 text-sm mt-1">{passwordError}</p>
            )}
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-semibold duration-150 ease-in-out"
          >
            Register
          </button>
          <span className="text-xs text-gray-400 float-left">
            * Registering is open for beta-testers
          </span>
        </form>
      </div>
    </>
  );
}

export default Register;
