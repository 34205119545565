import {
  AiOutlineCopy,
  AiOutlineDelete,
  AiOutlineEdit,
  AiOutlineStar,
  AiFillStar,
  AiOutlineUndo,
  AiOutlineDelete as AiOutlineDeletePermanently,
} from "react-icons/ai";
import { format } from "date-fns";
import { useState, useEffect, useCallback } from "react";
import { MdArchive, MdLabelOutline, MdUnarchive } from "react-icons/md";
import { serverTimestamp } from "firebase/firestore";

function Note({
  labels,
  userNotes,
  onNoteCopy,
  onNoteDelete,
  onNoteRestore,
  onNoteStarring,
  onNoteSaveEdit,
  onNoteArchiving,
  showStar = true,
  showCopy = true,
  showEdit = true,
  onLabelAssigning,
  showLabels = true,
  showDelete = true,
  showArchive = true,
  onNotePermanentDelete,
  showUnarchive = false,
  showPDAndRestore = false,
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNote, setSelectedNote] = useState(null);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [isEditingMode, setIsEditingMode] = useState(false);
  const [isLabelModalOpen, setIsLabelModalOpen] = useState(false);

  // Truncate text beyond a specified word limit
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  // Function to OPEN a modal for selected note
  const handleNoteClick = (note) => {
    setSelectedNote(note);
    setIsModalOpen(true);
  };

  // Function to handle modal CLOSE
  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedNote(null);
    setIsLabelModalOpen(false);
    setIsEditingMode(false);
  }, []);

  // Handle keydown event ESCAPE
  const handleKeyDown = useCallback(
    (event) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    },
    [handleCloseModal]
  );

  // Handle clicks outside the modal
  const handleClickOutside = useCallback(
    (event) => {
      if (event.target.classList.contains("modal-overlay")) {
        handleCloseModal();
      }
    },
    [handleCloseModal]
  );

  // useEffect to add/remove event listeners based on modal state
  useEffect(() => {
    if (isModalOpen) {
      document.addEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClickOutside);
    } else {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isModalOpen, handleKeyDown, handleClickOutside]);

  // Function to handle starring a note
  const handleStarring = (noteId) => {
    if (onNoteStarring) {
      onNoteStarring(noteId);
    }
  };

  // Function to toggle star status inside modal
  const toggleStarStatus = () => {
    if (!selectedNote) return;

    const updatedNote = {
      ...selectedNote,
      noteStatus: selectedNote.noteStatus === "active" ? "starred" : "active",
    };

    setSelectedNote(updatedNote);
    handleStarring(selectedNote.id);
  };

  // Function to handle archiving of notes
  const handleArchiving = () => {
    if (!selectedNote) return;
    onNoteArchiving(selectedNote.id);
    handleCloseModal();
  };

  // Function to handle copying of the note content
  const handleCopy = () => {
    if (selectedNote && onNoteCopy) {
      onNoteCopy(selectedNote.noteBody);
    }
  };

  // Function to handle deleting of notes
  const handleDeleting = () => {
    if (!selectedNote) return;
    onNoteDelete(selectedNote.id);
    handleCloseModal();
  };

  // Function to handle restoring of notes
  const handleRestoring = () => {
    if (!selectedNote) return;
    onNoteRestore(selectedNote.id);
    handleCloseModal();
  };

  // Function to handle permanent deletion of notes
  const handlePermanentDelete = () => {
    if (!selectedNote) return;
    onNotePermanentDelete(selectedNote.id);
    handleCloseModal();
  };

  // Function to toggle label modal
  const toggleLabelModal = () => {
    setIsLabelModalOpen(!isLabelModalOpen);
  };

  // Function to handle label assignment
  const handleLabelChange = (label) => {
    setSelectedLabels((prevLabels) =>
      prevLabels.includes(label)
        ? prevLabels.filter((l) => l !== label)
        : [...prevLabels, label]
    );
  };

  // Function to save a new label
  const handleLabelSave = () => {
    if (selectedNote && selectedLabels.length > 0) {
      onLabelAssigning(selectedNote.id, selectedLabels);
    }
    setIsLabelModalOpen(false);
  };

  // Enables editing mode for selected note
  const handleEditNote = () => {
    setIsEditingMode(true);
  };

  // Saves the changes made in editing mode
  const handleSaveEdit = () => {
    if (selectedNote) {
      onNoteSaveEdit(selectedNote.id, {
        noteTitle: selectedNote.noteTitle,
        noteBody: selectedNote.noteBody,
        lastEdited: serverTimestamp(),
      });

      // Set lastEdited to the current date locally
      setSelectedNote((prevNote) => ({
        ...prevNote,
        lastEdited: new Date(),
      }));

      setIsEditingMode(false);
    }
  };

  // Cancel the editing of note
  const handleCancelEdit = () => {
    setSelectedNote((prevNote) => ({
      ...prevNote,
      noteTitle: prevNote.originalNoteTitle,
      noteBody: prevNote.originalNoteBody,
    }));
    setIsEditingMode(false);
    handleCloseModal();
  };

  return (
    <div className="mt-8">
      {userNotes && userNotes.length > 0 ? (
        <div className="grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          {userNotes.map((note) => (
            <div
              key={note.id}
              className="relative p-4 rounded border border-gray-200 hover:shadow-md transition-shadow duration-200 cursor-pointer"
              onClick={() => handleNoteClick(note)}
            >
              {note.noteStatus === "starred" && (
                <div className="absolute top-0 right-0 mt-2 mr-2">
                  <AiFillStar className="text-yellow-400" />
                </div>
              )}
              <h3 className="text-md font-medium mb-2">{note.noteTitle}</h3>
              <p className="text-sm text-gray-700 break-words whitespace-pre-wrap">
                {truncateText(note.noteBody, 25)}
              </p>
            </div>
          ))}
        </div>
      ) : (
        <div className="flex justify-center min-w-[calc(100vw-42rem)]">
          <div className="text-center text-gray-400">
            {userNotes ? "No notes available." : "Loading..."}
          </div>
        </div>
      )}

      {isModalOpen && selectedNote && (
        <div className="modal-overlay fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white pb-6 pt-0 pr-0 rounded shadow-lg min-w-[25vw] max-w-[35vw] max-h-[80vh] relative flex flex-col">
            <div className="flex-grow custom-scrollbar overflow-y-auto">
              {isEditingMode ? (
                <div>
                  <input
                    type="text"
                    value={selectedNote.noteTitle}
                    onChange={(e) =>
                      setSelectedNote({
                        ...selectedNote,
                        noteTitle: e.target.value,
                      })
                    }
                    className="text-xl font-semibold mx-2 mt-4 p-2 w-[98%] focus:outline-none"
                  />
                  <textarea
                    value={selectedNote.noteBody}
                    onChange={(e) =>
                      setSelectedNote({
                        ...selectedNote,
                        noteBody: e.target.value,
                      })
                    }
                    className="text-left mx-2 mb-2 p-2 text-gray-700 w-[98%] focus:outline-none resize-none h-[50vh] custom-scrollbar overflow-y-auto"
                  />
                </div>
              ) : (
                <>
                  <h3 className="text-xl font-semibold mr-8 mb-4 mt-4 pl-6">
                    {selectedNote.noteTitle}
                  </h3>
                  <p className="text-left mr-2 mb-2 pl-6 text-gray-700 whitespace-pre-wrap">
                    {selectedNote.noteBody}
                  </p>
                  <span className="float-right text-xs text-gray-500 my-2 mr-3">
                    {selectedNote.lastEdited &&
                    typeof selectedNote.lastEdited.toDate === "function"
                      ? // If lastEdited exists and is a Firebase Timestamp
                        "Last Edited " +
                        format(
                          selectedNote.lastEdited.toDate(),
                          selectedNote.lastEdited.toDate().getFullYear() !==
                            new Date().getFullYear()
                            ? "MMMM d, yyyy"
                            : "MMMM d"
                        )
                      : selectedNote.lastEdited instanceof Date
                      ? // If lastEdited is a JavaScript Date object
                        "Last Edited " +
                        format(
                          selectedNote.lastEdited,
                          selectedNote.lastEdited.getFullYear() !==
                            new Date().getFullYear()
                            ? "MMMM d, yyyy"
                            : "MMMM d"
                        )
                      : selectedNote.save_timing &&
                        typeof selectedNote.save_timing.toDate === "function"
                      ? // If save_timing exists and is a Firebase Timestamp
                        "Created on " +
                        format(
                          selectedNote.save_timing.toDate(),
                          selectedNote.save_timing.toDate().getFullYear() !==
                            new Date().getFullYear()
                            ? "MMMM d, yyyy"
                            : "MMMM d"
                        )
                      : // Fallback if neither lastEdited nor save_timing are available
                        "Refresh to see date"}
                  </span>
                </>
              )}
            </div>

            <div className="flex bg-white h-full text-center -mb-6 py-2 border-t rounded-b border-gray-200">
              <div className="flex space-x-8 ml-6 my-auto">
                {showStar &&
                  !isEditingMode &&
                  (selectedNote.noteStatus === "starred" ? (
                    <AiFillStar
                      className="cursor-pointer text-yellow-400 hover:text-yellow-600 duration-200 ease-in-out"
                      title="Remove favorite"
                      size={20}
                      onClick={toggleStarStatus}
                    />
                  ) : (
                    <AiOutlineStar
                      className="cursor-pointer text-gray-600 hover:text-yellow-400 duration-200 ease-in-out"
                      title="Favorite"
                      size={20}
                      onClick={toggleStarStatus}
                    />
                  ))}

                {showLabels && !isEditingMode && (
                  <MdLabelOutline
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Add labels"
                    size={20}
                    onClick={toggleLabelModal}
                  />
                )}

                {showArchive && !isEditingMode && (
                  <MdArchive
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Archive"
                    size={20}
                    onClick={handleArchiving}
                  />
                )}

                {showUnarchive && !isEditingMode && (
                  <MdUnarchive
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Unarchive"
                    size={20}
                    onClick={handleArchiving}
                  />
                )}

                {showCopy && !isEditingMode && (
                  <AiOutlineCopy
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Copy"
                    size={20}
                    onClick={handleCopy}
                  />
                )}

                {showEdit && !isEditingMode && (
                  <AiOutlineEdit
                    className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                    title="Edit"
                    size={20}
                    onClick={handleEditNote}
                  />
                )}

                {showDelete && !isEditingMode && (
                  <AiOutlineDelete
                    className="cursor-pointer text-gray-600 hover:text-red-400 duration-200 ease-in-out"
                    title="Delete"
                    size={20}
                    onClick={handleDeleting}
                  />
                )}

                {selectedNote.noteStatus === "binned" && showPDAndRestore && (
                  <>
                    <AiOutlineUndo
                      className="cursor-pointer text-gray-600 hover:text-gray-500 duration-200 ease-in-out"
                      title="Restore this note"
                      size={20}
                      onClick={handleRestoring}
                    />
                    <AiOutlineDeletePermanently
                      className="cursor-pointer text-gray-600 hover:text-red-400 duration-200 ease-in-out"
                      title="Delete Permanently"
                      size={20}
                      onClick={handlePermanentDelete}
                    />
                  </>
                )}
              </div>

              {!isEditingMode && (
                <button
                  className="p-[6px] text-sm text-gray-600 rounded hover:bg-gray-200 ml-auto mr-2"
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              )}

              {isEditingMode && (
                <div className="flex ml-auto my-auto">
                  <button
                    className="p-2 mr-2 text-sm text-gray-600 rounded bg-gray-200 hover:bg-gray-300"
                    onClick={handleSaveEdit}
                  >
                    Save
                  </button>
                  <button
                    className="p-2 mr-2 text-sm text-gray-600 rounded bg-gray-200 hover:bg-gray-300"
                    onClick={handleCancelEdit}
                  >
                    Cancel
                  </button>
                </div>
              )}
            </div>
          </div>

          {isLabelModalOpen && (
            <div className="modal-overlay fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
              <div className="bg-white rounded shadow-lg w-[90vw] max-w-[20rem] max-h-[80vh] relative flex flex-col p-4">
                <span className="text-lg font-semibold mb-4">
                  Assign Labels
                </span>
                <div className="flex flex-col space-y-2">
                  {labels.map((label, index) => (
                    <li
                      key={index}
                      className={`flex items-center justify-between text-gray-800 bg-gray-100 p-2 mx-3 rounded cursor-pointer transition duration-200 ${
                        selectedLabels.includes(label) ? "bg-gray-200" : ""
                      }`}
                      onClick={() => handleLabelChange(label)}
                    >
                      <div className="flex items-center">
                        <span className="text-md font-medium">
                          {label.labelName}
                        </span>
                      </div>
                      <input
                        type="checkbox"
                        checked={selectedLabels.includes(label)}
                        readOnly
                        className="form-checkbox w-4 h-4"
                      />
                    </li>
                  ))}
                </div>
                <button
                  className="mt-4 p-[6px] text-sm text-gray-600 rounded hover:bg-gray-200 self-end"
                  onClick={handleLabelSave}
                >
                  Save
                </button>
                <button
                  className="mt-4 p-[6px] text-sm text-gray-600 rounded hover:bg-gray-200 self-end"
                  onClick={toggleLabelModal}
                >
                  Close
                </button>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default Note;
