import {
  BiSend,
  BiLockAlt,
  BiPaperclip,
  BiDotsVerticalRounded,
} from "react-icons/bi";
import {
  doc,
  getDoc,
  updateDoc,
  arrayUnion,
  onSnapshot,
} from "firebase/firestore";
import {
  AiOutlineDelete,
  AiOutlineArrowRight,
  AiOutlineInfoCircle,
} from "react-icons/ai";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import { db } from "../../firebase";
import EmojiPicker from "@emoji-mart/react";
import { FiTrash, FiX } from "react-icons/fi";
import { CgSmileMouthOpen } from "react-icons/cg";
import { Link, useLocation } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { useState, useEffect, useRef, useContext } from "react";
import { MdArchive, MdBlock, MdUnarchive } from "react-icons/md";

function ExpandedArchiveView({
  name,
  chatDocId,
  blockUser,
  deleteChat,
  unarchiveChat,
  deleteReadOnlyCopy,
}) {
  const modalRef = useRef(null);
  const location = useLocation();
  const textareaRef = useRef(null);
  const emojiPickerRef = useRef(null);
  const seeWhyModalRef = useRef(null);
  const readOnlyModalRef = useRef(null);
  const UnarchiveModalRef = useRef(null);
  const blockUserModalRef = useRef(null);
  const chatDeleteModalRef = useRef(null);
  const chatCopyDeleteModalRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const { currentUser } = useContext(AuthContext);
  const [blockedAt, setBlockedAt] = useState(null);
  const [newMessage, setNewMessage] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const [displayName, setDisplayName] = useState("");
  const [archivedTime, setArchivedTime] = useState("");
  const [P1BlockedP2, setP1BlockedP2] = useState(false);
  const [P2BlockedP1, setP2BlockedP1] = useState(false);
  const [showWhyModal, setShowWhyModal] = useState(false);
  const [readOnlyArchive, setreadOnlyArchive] = useState(false);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [participantStatus, setParticipantStatus] = useState("");
  const [showReadOnlyModal, setShowReadOnlyModal] = useState(false);
  const [showUnarchiveModal, setShowUnarchiveModal] = useState(false);
  const [showBlockUserModal, setShowBlockUserModal] = useState(false);
  const [showChatOptionModal, setShowChatOptionModal] = useState(false);
  const [showChatDeleteModal, setShowChatDeleteModal] = useState(false);
  const [firstParticipantEmail, setFirstParticipantEmail] = useState("");
  const [secondParticipantEmail, setSecondParticipantEmail] = useState("");
  const [showChatCopyDeleteModal, setShowChatCopyDeleteModal] = useState(false);

  // Function to set the display name based on provided name prop
  useEffect(() => {
    const formatDisplayName = () => {
      if (name) {
        const formattedName = name
          .split("-")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        setDisplayName(formattedName);
      } else {
        setDisplayName("");
      }
    };

    // Fetch all chats/messages of the user
    const fetchMessages = async () => {
      if (chatDocId) {
        const chatDoc = await getDoc(doc(db, "chats", chatDocId));
        if (chatDoc.exists()) {
          setMessages(chatDoc.data().messages || []);
        } else {
          // If the chat doesn't exist (deleted), clear the messages
          setMessages([]);
        }
      }
    };
    formatDisplayName();

    if (chatDocId) {
      fetchMessages();
      // Listen for real-time updates only if chatDocId is valid
      const unsubscribe = onSnapshot(
        doc(db, "chats", chatDocId),
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            setMessages(docSnapshot.data().messages || []);
          } else {
            // If the chat was deleted, clear the messages
            setMessages([]);
          }
        }
      );

      return () => unsubscribe();
    }
  }, [name, chatDocId]);

  // Handle emoji icon click
  const handleEmojiClick = (emoji) => {
    setNewMessage((prevMessage) => prevMessage + emoji.native);
    setShowEmojiPicker(false);
  };

  // Handle clicks outside emoji-picker modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        emojiPickerRef.current &&
        !emojiPickerRef.current.contains(event.target)
      ) {
        setShowEmojiPicker(false);
      }
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        setShowChatOptionModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowEmojiPicker(false);
        setShowChatOptionModal(false);
      }
    };

    document.addEventListener("keydown", handleEscKey);
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("keydown", handleEscKey);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle clicks outside unarchive modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        UnarchiveModalRef.current &&
        !UnarchiveModalRef.current.contains(event.target)
      ) {
        setShowUnarchiveModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowUnarchiveModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle clicks outside read-only modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        readOnlyModalRef.current &&
        !readOnlyModalRef.current.contains(event.target)
      ) {
        setShowReadOnlyModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowReadOnlyModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle clicks outside block user modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        blockUserModalRef.current &&
        !blockUserModalRef.current.contains(event.target)
      ) {
        setShowBlockUserModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowBlockUserModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle clicks outside chat deletion modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatDeleteModalRef.current &&
        !chatDeleteModalRef.current.contains(event.target)
      ) {
        setShowChatDeleteModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowChatDeleteModal(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle clicks outside seeWhy modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        seeWhyModalRef.current &&
        !seeWhyModalRef.current.contains(event.target)
      ) {
        setShowWhyModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowWhyModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Handle clicks outside 'delete read-only copy' modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatCopyDeleteModalRef.current &&
        !chatCopyDeleteModalRef.current.contains(event.target)
      ) {
        setShowChatCopyDeleteModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowChatCopyDeleteModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  useEffect(() => {
    const textarea = textareaRef.current;
    // Check if textarea is defined before accessing its properties
    if (textarea) {
      textarea.style.height = "auto";

      // Dynamically set height based on scrollHeight
      const scrollHeight = textarea.scrollHeight;
      const maxHeight = 4 * 24;
      // Set height based on content and maxHeight
      textarea.style.height = `${Math.min(scrollHeight, maxHeight)}px`;
    }
  }, [newMessage]);

  // Function to facilitate messaging
  const handleSendMessage = async () => {
    if (newMessage.trim()) {
      const messageId = uuidv4();
      const newMsg = {
        text: newMessage,
        sender: currentUser.email,
        time: new Date().toISOString(),
        id: messageId,
      };
      setNewMessage("");

      // Save message to firestore
      const chatDoc = doc(db, "chats", chatDocId);

      // Save the message with the ISO timestamp string
      await updateDoc(chatDoc, {
        messages: arrayUnion(newMsg),
      });
    }
  };

  // Facilitate next line functionality for messages
  const handleNextLine = (event) => {
    if (event.key === "Enter" && event.shiftKey) {
      return; // Allow new line
    } else if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  // useEffect to fetch participant status
  useEffect(() => {
    const fetchParticipantStatus = async () => {
      if (chatDocId) {
        const chatDocRef = doc(db, "chats", chatDocId);
        const chatDocSnap = await getDoc(chatDocRef);

        // Check document existence
        if (chatDocSnap.exists()) {
          const chatData = chatDocSnap.data();
          const {
            participant1,
            participant2,
            P1Status,
            P2Status,
            status,
            archivedAt,
          } = chatData;

          // Check if status field exists
          // If yes, set readOnlyArchive to true
          if (status && status === "read-only-archive") {
            setreadOnlyArchive(true);
            setArchivedTime(archivedAt);
          } else {
            // If currentUser is participant1 or participant2, update status accordingly
            if (participant1 === currentUser.email) {
              setParticipantStatus(P1Status || status);
            } else if (participant2 === currentUser.email) {
              setParticipantStatus(P2Status || status);
            }

            // Set readOnlyArchive to false if status doesn't match 'read-only-archive'
            setreadOnlyArchive(false);
          }
        }
      }
    };

    if (chatDocId) {
      fetchParticipantStatus();
    }
  }, [chatDocId, currentUser.email]);

  // useEffect to fetch blocked status in real-time
  useEffect(() => {
    if (!chatDocId) return;

    const chatDocRef = doc(db, "chats", chatDocId);

    // Real-time listener for changes to the chat document
    const unsubscribe = onSnapshot(chatDocRef, (chatDocSnap) => {
      // Check if the document exists
      if (chatDocSnap.exists()) {
        const chatData = chatDocSnap.data();
        const {
          participant1,
          participant2,
          P1BlockedP2,
          P2BlockedP1,
          P1BlockedAt,
          P2BlockedAt,
        } = chatData;

        setFirstParticipantEmail(participant1);
        setSecondParticipantEmail(participant2);
        setP1BlockedP2(P1BlockedP2);
        setP2BlockedP1(P2BlockedP1);

        // Determine if the current user is blocked
        if (participant1 === currentUser.email) {
          setIsBlocked(P1BlockedP2);
          setBlockedAt(P1BlockedAt);
        } else if (participant2 === currentUser.email) {
          setIsBlocked(P2BlockedP1);
          setBlockedAt(P2BlockedAt);
        }
      }
    });

    // Cleanup function
    return () => unsubscribe();
  }, [chatDocId, currentUser.email]);

  return (
    <div className="flex-1 flex flex-col border border-gray-300 rounded-r-md relative">
      {(chatDocId && participantStatus === "archived") ||
      (chatDocId && readOnlyArchive === true) ? (
        <div className="flex items-center justify-between p-4 bg-gray-100 border-b border-gray-300">
          <div className="flex items-center space-x-4">
            <span className="text-lg font-semibold text-gray-900">
              {displayName}
            </span>
          </div>
          {readOnlyArchive !== true ? (
            // Archived Chat
            <BiDotsVerticalRounded
              className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900"
              onClick={() => setShowChatOptionModal(!showChatOptionModal)}
              title="See more options"
            />
          ) : (
            // Read-only Copy
            <BiDotsVerticalRounded
              className="w-6 h-6 cursor-pointer text-gray-700 hover:text-gray-900"
              onClick={() => setShowReadOnlyModal(!showReadOnlyModal)}
              title="See more options"
            />
          )}

          {showChatOptionModal && readOnlyArchive !== true && (
            <div
              ref={modalRef}
              className="absolute bg-white shadow-md rounded-md md:min-w-[8vw] sm:min-w-[12vw] right-2 top-14 p-2 z-50"
            >
              <ul className="space-y-2 text-[17px] mx-auto">
                <li
                  className="flex text-gray-600 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
                  onClick={() => setShowUnarchiveModal(true)}
                >
                  <MdUnarchive className="mx-1" /> Unarchive
                </li>
                <li className="flex text-gray-600 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200">
                  <BiLockAlt className="mx-1" /> Lock chat
                </li>
                <li
                  className="flex text-gray-400 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
                  onClick={() => setShowBlockUserModal(true)}
                >
                  <MdBlock className="mx-1" /> {isBlocked ? "Unblock" : "Block"}
                </li>
                <li
                  className="flex text-red-500 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
                  onClick={() => setShowChatDeleteModal(true)}
                >
                  <AiOutlineDelete className="mx-1" /> Delete chat
                </li>
              </ul>
            </div>
          )}
        </div>
      ) : null}

      {/* More options modal */}
      {showReadOnlyModal && readOnlyArchive === true && (
        <div
          ref={readOnlyModalRef}
          className="absolute bg-white shadow-md rounded-md md:min-w-[8vw] sm:min-w-[12vw] right-2 top-14 p-2 z-50"
        >
          <ul className="space-y-2 text-[17px] mx-auto">
            <li className="flex text-gray-600 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200">
              <BiLockAlt className="mx-1" /> Lock chat
            </li>

            <li
              className="flex text-red-500 font-medium p-1 items-center rounded hover:bg-gray-100 cursor-pointer transition duration-200"
              onClick={() => setShowChatCopyDeleteModal(true)}
            >
              <AiOutlineDelete className="mx-1" /> Delete chat
            </li>
          </ul>
        </div>
      )}

      {/* Unarchive modal */}
      {showUnarchiveModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={UnarchiveModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%] space-y-3"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-800">
                Unarchive Chat
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowUnarchiveModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 border bg-gray-300" />
            <div className="flex flex-col items-center space-y-4">
              <p className="text-gray-600 max-w-[35rem] text-center">
                Do you want to unarchive this chat?
              </p>
              <button
                className="flex bg-blue-500 text-white px-4 py-2 w-full md:w-auto rounded hover:bg-blue-600"
                onClick={() => unarchiveChat(setShowUnarchiveModal)}
              >
                <MdUnarchive className="text-xl mr-1 mt-[0.09rem]" />
                Unarchive
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Block user modal */}
      {showBlockUserModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={blockUserModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                {isBlocked ? "Unblock User" : "Block User"}
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowBlockUserModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-300" />
            <div className="flex flex-col items-center">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                Are you sure you want to {isBlocked ? "unblock" : "block"}{" "}
                <span className="font-semibold">{displayName}</span>?
              </p>
              <button
                className="flex text-md font-medium text-gray-400 p-2 w-full md:w-auto rounded hover:bg-gray-100 ease-in-out duration-200"
                onClick={() => blockUser(setShowBlockUserModal)}
              >
                <MdBlock className="mt-[0.24rem] mr-1" />
                {isBlocked ? "Unblock User" : "Block User"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Chat copy deletion modal */}
      {showChatCopyDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={chatCopyDeleteModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[40%] lg:w-[22%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Archived Copy
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowChatCopyDeleteModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-400" />

            <div className="flex flex-col items-center">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                The chat you are attempting to delete is a{" "}
                <span className="font-semibold">read-only archive</span>. This
                is a irreversible action and you will not be able to access the
                copy afterwards. Are you sure you want to do this?
              </p>
              <button
                className="flex text-md font-medium text-red-500 p-2 w-full md:w-auto rounded hover:bg-red-500 hover:text-white ease-in-out duration-200"
                onClick={() => deleteReadOnlyCopy(setShowChatCopyDeleteModal)}
              >
                <FiTrash className="mt-[0.2rem] mr-1" />
                Delete chat copy
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Archived chat deletion modal */}
      {showChatDeleteModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={chatDeleteModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[40%] lg:w-[22%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Archived Chat
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowChatDeleteModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-400" />

            <div className="flex flex-col items-center">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                The chat you are attempting to delete is a archived chat. This
                is a irreversible action and you will not be able to access the
                it afterwards. Are you sure you want to do this?
              </p>
              <button
                className="flex text-md font-medium text-red-500 p-2 w-full md:w-auto rounded hover:bg-red-500 hover:text-white ease-in-out duration-200"
                onClick={() => deleteChat(setShowChatDeleteModal)}
              >
                <FiTrash className="mt-[0.2rem] mr-1" />
                Delete archived chat
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Message display */}
      <div className="flex-1 my-0 p-4 space-y-4 bg-gray-100 custom-scrollbar overflow-y-auto">
        {(chatDocId && participantStatus === "archived") ||
        (chatDocId && readOnlyArchive === true)
          ? messages.map((msg, index) => {
              const isPreviousMessageFromSameSender =
                index > 0 && messages[index - 1].sender === msg.sender;
              return (
                <div
                  key={index}
                  className={`flex ${
                    msg.sender === currentUser.email
                      ? "justify-end"
                      : "justify-start"
                  }`}
                  style={{
                    marginTop: isPreviousMessageFromSameSender ? "0" : "1rem",
                  }}
                >
                  <div
                    className={`rounded-lg min-w-24 mb-3 ${
                      msg.sender === currentUser.email
                        ? "bg-blue-500 text-white"
                        : "bg-gray-300 text-gray-700"
                    }`}
                  >
                    <p className="text-md p-3 pb-0 max-w-[30rem] break-words overflow-hidden whitespace-pre-wrap">
                      {msg.text}
                    </p>
                    <p
                      className={`text-[0.65rem] font-medium text-right -mt-[0.15rem] mb-[4px] mr-[6px] ${
                        msg.sender === currentUser.email
                          ? "text-gray-200"
                          : "text-gray-500"
                      }`}
                    >
                      {msg.time
                        ? (() => {
                            const messageDate = new Date(msg.time);
                            const now = new Date();
                            const differenceInDays =
                              (now - messageDate) / (1000 * 60 * 60 * 24);

                            if (differenceInDays < 1) {
                              return format(messageDate, "HH:mm");
                            } else if (differenceInDays < 30) {
                              return format(messageDate, "EEE. HH:mm");
                            } else if (
                              messageDate.getFullYear() === now.getFullYear()
                            ) {
                              return format(messageDate, "d MMM.");
                            } else {
                              return format(messageDate, "d MMM. yyyy");
                            }
                          })()
                        : "Loading..."}
                    </p>
                  </div>
                </div>
              );
            })
          : null}

        {/* User blocked banner */}
        {isBlocked && (
          <div className="bg-gradient-to-r from-teal-200 via-teal-300 to-teal-200 text-teal-900 p-4 text-center">
            <p className="text-sm font-medium">
              You blocked {displayName} on{" "}
              {blockedAt
                ? blockedAt.toDate().toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })
                : "N/A"}
            </p>
          </div>
        )}

        {/* Resting info page */}
        <div
          className={`relative ${
            location.pathname === "/starchat/archive" ? "h-[89vh]" : ""
          } bg-gray-100 flex flex-col items-center justify-center`}
        >
          {!chatDocId && (
            <div className="text-center mx-auto">
              <h1 className="flex text-4xl font-semibold text-gray-800 mb-1">
                <MdArchive className="mr-2" />
                Archived chats
              </h1>
            </div>
          )}
        </div>
      </div>

      {/* Message bar */}
      {chatDocId && participantStatus === "archived" ? (
        <div className="flex items-center p-3 border-t border-gray-300 bg-gray-100">
          {(P1BlockedP2 && currentUser.email === secondParticipantEmail) ||
          (P2BlockedP1 && currentUser.email === firstParticipantEmail) ||
          (P1BlockedP2 && P2BlockedP1) ? (
            // Problem display bar
            <div className="flex justify-between items-center w-full text-gray-700 px-2">
              <p className="text-sm font-medium">
                You can no longer communicate with {displayName}
              </p>
              <span
                className="text-blue-600 text-sm font-medium cursor-pointer"
                onClick={() => setShowWhyModal(true)}
              >
                See why
              </span>
            </div>
          ) : (
            // Original message bar when not blocked
            <>
              <BiPaperclip
                className="w-6 h-6 cursor-pointer text-gray-400 mr-2"
                title="File uploads coming soon!"
              />
              <CgSmileMouthOpen
                className="w-6 h-6 cursor-pointer text-gray-400 mr-2"
                onClick={() => setShowEmojiPicker((prev) => !prev)}
                title="Select emoji"
              />
              {showEmojiPicker && (
                <div
                  ref={emojiPickerRef}
                  className="absolute bottom-20 left-10"
                >
                  <EmojiPicker onEmojiSelect={handleEmojiClick} />
                </div>
              )}
              <textarea
                ref={textareaRef}
                type="text"
                placeholder="Type your message..."
                value={newMessage}
                onChange={(e) => setNewMessage(e.target.value)}
                onKeyDown={(e) => handleNextLine(e)}
                className="flex-1 p-3 text-sm rounded-3xl bg-gray-200 focus:outline-none text-gray-900 resize-none custom-scrollbar overflow-y-auto max-h-[96px]"
                rows={1}
              />
              <button
                onClick={handleSendMessage}
                className="ml-2 p-1 rounded text-gray-600"
              >
                <BiSend className="w-6 h-6" title="Send message" />
              </button>
            </>
          )}
        </div>
      ) : null}

      {/* See why modal */}
      {showWhyModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={seeWhyModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">Note</h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setShowWhyModal(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border bg-gray-300" />
            <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
              There might be several reasons why you're not able to chat. Click{" "}
              <i>learn more</i> to see further information on why you may not be
              able to chat with <b>{displayName}</b>.
            </p>
            <Link
              to="/documentation/starchat"
              className="flex items-center justify-center text-blue-500 text-sm hover:underline space-x-1"
            >
              <span>Learn more</span>
              <AiOutlineArrowRight className="text-xs" />
            </Link>
          </div>
        </div>
      )}

      {/* Read-only archive information banner */}
      {chatDocId && readOnlyArchive === true && (
        <div className="flex items-center justify-between py-4 px-6 bg-gradient-to-r from-blue-200 to-blue-100 rounded-t-lg">
          <div className="my-auto">
            <div className="flex items-center text-blue-800 -mb-1">
              <AiOutlineInfoCircle className="text-2xl mr-2" />
              <p className="text-lg font-semibold">
                This is a read-only archive
              </p>
            </div>
            <span className="text-sm text-gray-500 ml-8">
              Archived on
              {archivedTime &&
                " " +
                  archivedTime.toDate().toLocaleDateString("en-US", {
                    day: "numeric",
                    month: "short",
                    year: "numeric",
                  })}
            </span>
          </div>
          <Link
            to="/documentation/starchat"
            className="flex items-center text-blue-500 text-sm hover:underline space-x-1"
          >
            <span>Learn more</span>
            <AiOutlineArrowRight className="text-xs" />
          </Link>
        </div>
      )}
    </div>
  );
}

export default ExpandedArchiveView;
