import "../../scrollerStyle.css";
import { useState } from "react";
import { FiX } from "react-icons/fi";
import { db, auth } from "../../firebase";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { addDoc, updateDoc, collection } from "firebase/firestore";

function AddContact({ toggle }) {
  const [birthday, setBirthday] = useState(null);
  const [contactPhone, setContactPhone] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactAddress, setContactAddress] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [contactFirstName, setContactFirstName] = useState("");

  const navigate = useNavigate(); // Initialize navigate

  // Handle new contact submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    // Constructing name of the database collection
    const collectionTitle = "contacts-" + auth.currentUser.email;
    try {
      // Add a new document with a generated ID
      const docRef = await addDoc(collection(db, collectionTitle), {
        firstName: contactFirstName,
        lastName: contactLastName,
        phone: contactPhone,
        email: contactEmail,
        address: contactAddress,
        birthday: birthday,
        starred: false,
        archived: false,
      });
      // Update the document with its own ID
      await updateDoc(docRef, { id: docRef.id });

      alert("Contact has been saved");
      navigate("/starconnect");
    } catch (err) {
      console.error("Error adding document: ", err);
    }
  };

  return (
    <div className="p-6 bg-white custom-scrollbar overflow-y-auto relative">
      <button
        className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        onClick={() => {
          navigate("/starconnect");
          toggle(false);
        }}
      >
        <FiX size={24} />
      </button>
      <h1 className="text-center text-xl font-semibold mb-6">Add Contact</h1>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center mb-4">
          <img
            src="https://via.placeholder.com/100"
            alt="Profile"
            className="w-16 h-16 rounded-full mr-4"
          />
          <div className="flex flex-col space-y-2">
            <input
              type="text"
              placeholder="First Name"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setContactFirstName(e.target.value)}
              value={contactFirstName}
              required
            />
            <input
              type="text"
              placeholder="Last Name"
              className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              onChange={(e) => setContactLastName(e.target.value)}
              value={contactLastName}
            />
          </div>
        </div>

        <div>
          <label className="block mb-1 font-medium">Email</label>
          <input
            type="email"
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Email"
            onChange={(e) => setContactEmail(e.target.value)}
            value={contactEmail}
          />
        </div>

        <div>
          <label className="block mb-1 font-medium">Phone</label>
          <input
            type="tel"
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Phone"
            onChange={(e) => setContactPhone(e.target.value)}
            value={contactPhone}
          />
        </div>

        <div>
          <label className="block mb-1 font-medium">Address</label>
          <input
            type="text"
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            placeholder="Address"
            onChange={(e) => setContactAddress(e.target.value)}
            value={contactAddress}
          />
        </div>

        <div>
          <label className="block mb-1 font-medium">Birthday</label>
          <DatePicker
            selected={birthday}
            className="block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
            onChange={(bDate) => setBirthday(bDate)}
          />
        </div>

        <button
          type="submit"
          className="w-full py-2 bg-blue-500 text-white font-semibold rounded-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          Save
        </button>
      </form>
    </div>
  );
}

export default AddContact;
