import React from "react";

function AccountInfo() {
  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Account Information
      </h1>
      <p className="text-gray-700 mb-8">
        Welcome to the Account Information section of Starrvault. Here, you can
        manage your account settings and personal information to ensure a smooth
        user experience.
      </p>

      {/* Password Reset */}
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg shadow mb-8">
        <h2 className="text-2xl font-semibold text-blue-800 mb-2">
          Resetting Your Password
        </h2>
        <p className="text-blue-700 mb-2">
          If you need to reset your password, follow these steps:
        </p>
        <div className="p-2 ml-6 mb-2 rounded-xl border-2 border-blue-200">
          <ul className="text-blue-700 list-inside space-y-2">
            <li>
              <i>Step 1:</i> Navigate to the "Settings" tab in your account
              dashboard.
            </li>
            <li>
              <i>Step 2:</i> Select the "Account" section.
            </li>
            <li>
              <i>Step 3:</i> Click on the "Reset Password", present inside the
              'Account actions' card
            </li>
            <li>
              <i>Step 4:</i> You will be prompted to enter your password.
            </li>
            <li>
              <i>Step 5:</i> After typing the password and pressing the 'Reset
              Password' button, you will receive a mail with a link to reset the
              password
            </li>
            <li>
              <i>Step 6:</i> When you come back, you'll notice that you've been
              logged out automatically. Now type your newly set password and
              press <i>Enter</i> to login.
            </li>
          </ul>
        </div>
        <p className="text-blue-700">
          Remember to choose a strong password that combines letters, numbers,
          and symbols for optimal security.
        </p>
      </div>

      {/* Username Change */}
      <div className="bg-indigo-50 border-l-4 border-indigo-400 p-4 rounded-lg shadow mb-8">
        <h2 className="text-2xl font-semibold text-indigo-800 mb-2">
          Changing Your Username
        </h2>
        <p className="text-indigo-700 mb-2">
          To change your username, please follow these steps:
        </p>
        <div className="p-2 ml-6 mb-2 rounded-xl border-2 border-indigo-200">
          <ul className="text-indigo-700 list-inside space-y-2">
            <li>
              <i>Step 1:</i> Go to the "Account" section of your settings.
            </li>
            <li>
              <i>Step 2:</i> Find the "Change your username" card.
            </li>
            <li>
              <i>Step 3:</i> Enter your new username.
            </li>
            <li>
              <i>Step 4:</i> Click "Submit" and a popup will appear, asking you
              to confirm your new username. Once you've confirmed, your username
              will be changed.
            </li>
          </ul>
          <p className="text-indigo-700">
            Note that it may take a while for the system to reflect your new
            username
          </p>
        </div>
      </div>

      {/* Account Deletion */}
      <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg shadow mb-8">
        <h2 className="text-2xl font-semibold text-red-800 mb-2">
          Deleting Your Account
        </h2>
        <p className="text-red-700 mb-2">
          If you wish to delete your account, please be aware that this action
          is irreversible. To delete your account:
        </p>
        <div className="p-2 ml-6 mb-2 rounded-xl border-2 border-red-200">
          <ul className="text-red-700 list-inside space-y-2">
            <li>
              <i>Step 1:</i> Go to the "Settings" tab.
            </li>
            <li>
              <i>Step 2:</i> Find the 'Account actions' card and click on the
              Delete button.
            </li>
            <li>
              <i>Step 3:</i> You will be asked to confirm if you really want to
              delete your account.
            </li>
            <li>
              <i>Step 4:</i> If you are sure, click the 'Delete Account' button
              and your account will be deleted permanently.
            </li>
          </ul>
        </div>
        <p className="text-red-700">
          Ensure that you have backed up any important data before proceeding
          with account deletion.
        </p>
      </div>

      {/* 2 Factor Authentication */}
      <div className="bg-green-50 border-l-4 border-green-400 p-4 rounded-lg shadow">
        <h2 className="text-2xl font-semibold text-green-800 mb-2">
          Enabling Two-Factor Authentication (2FA)
        </h2>
        <p className="text-green-700 mb-2">
          For added security, consider enabling Two-Factor Authentication:
        </p>
        <div className="p-2 ml-6 mb-2 rounded-xl border-2 border-green-200">
          <ul className="text-green-700 list-inside space-y-2">
            <li>
              <i>Step 1:</i> Go to the "Account" section in your settings.
            </li>
            <li>
              <i>Step 2:</i> Toggle the "Enable Two-Factor Authentication"
              option.
            </li>
            <li>
              <i>Step 3:</i> Follow the instructions to link your authentication
              app or receive SMS codes.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default AccountInfo;
