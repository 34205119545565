import React from "react";

function StarNoteInfo() {
  return (
    <div className="mx-auto p-6">
      {/* Page Title */}
      <h1 className="text-3xl font-bold text-gray-800 mb-4">
        Using Star Note on Starrvault
      </h1>

      {/* Introduction */}
      <p className="text-gray-700 mb-8">
        Star Note is an intuitive and secure note-taking tool within Starrvault.
        Whether you're jotting down ideas, organizing to-do lists, or storing
        essential information, Star Note offers a clean and powerful interface.
        This guide will help you navigate and maximize the features of Star
        Note.
      </p>

      {/* Creating and Managing Notes */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Creating and Organizing Notes
      </h2>

      <p className="text-gray-700 mb-4">
        Here's how you can create, edit, and organize your notes in Star Note:
      </p>

      <div className="p-2 ml-6 rounded-xl border border-gray-300">
        <ul className="text-gray-700 list-inside space-y-2">
          <li>
            <i>Step 1:</i> Open Star Note from your dashboard.
          </li>
          <li>
            <i>Step 2:</i> Click on the “New Note” button to start creating a
            note.
          </li>
          <li>
            <i>Step 3:</i> Add your content, title, or tags as needed, and save
            your note.
          </li>
        </ul>
      </div>

      {/* Archiving and Restoring Notes */}
      <h2 className="text-2xl font-semibold text-gray-800 mt-8 mb-2">
        Archiving and Restoring Notes
      </h2>

      <p className="text-gray-700 mb-4">
        You can archive notes to declutter your active workspace without
        deleting them. Here's how:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-inside">
        <div className="p-2 rounded-xl border border-gray-300">
          <li className="mb-2">
            <i>Step 1:</i> Select the note you wish to archive.
          </li>
          <li className="mb-2">
            <i>Step 2:</i> Choose the “Archive” option from the note options
            menu.
          </li>
          <li>
            <i>Step 3:</i> Archived notes can be restored at any time by
            accessing the archive section and selecting “Restore.”
          </li>
        </div>
      </ul>

      {/* Binning and Deleting Notes */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Binning and Permanently Deleting Notes
      </h2>

      <p className="text-gray-700 mb-4">
        Notes that are no longer needed can be binned or permanently deleted.
        Here's the process:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-inside">
        <div className="p-2 rounded-xl border border-gray-300">
          <li className="mb-2">
            <i>Step 1:</i> Select the note you want to bin.
          </li>
          <li className="mb-2">
            <i>Step 2:</i> Move it to the bin by choosing the “Bin” option.
          </li>
          <li>
            <i>Step 3:</i> To delete a note permanently, go to the bin and
            choose “Delete Permanently.” (Note: Permanently deleted notes cannot
            be restored.)
          </li>
        </div>
      </ul>

      {/* Labels */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-1">
        Creating Labels
      </h2>
      <p>
        You can create labels to organize your created notes by assigning labels
        to them.
      </p>
      <ul className="ml-8 mb-8 list-disc">
        <li>
          To create a label, click on the 'Add Labels' text present in the
          sidebar
        </li>
        <li>
          Type the name of the label you want to create in the input saying{" "}
          <i>Create new label</i> and click on the green checkmark icon present
          in the right of the create new label input.
        </li>
        <li>
          Now your created label will be seen in the labels section in the
          sidebar.
        </li>
        <li>
          Now, if you open a note and click on the labels icon. Your created
          labels will appear in the popup and you can select any one from them
          and click on 'Save'
        </li>
        <li>
          Now, if you click on the label from the sidebar, all notes saved with
          that particlar label will appear in the right window
        </li>
      </ul>

      {/* Troubleshooting */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Troubleshooting Issues in Star Note
      </h2>

      <p className="text-gray-700 mb-8">
        If you experience issues with Star Note, such as problems accessing
        notes or saving changes, try the following:
      </p>

      <ul className="ml-6 mb-8 text-gray-700 list-disc list-inside">
        <li className="mb-2">
          <strong>Check your internet connection:</strong> Ensure you're
          connected to the internet to sync your notes.
        </li>
        <li className="mb-2">
          <strong>Clear browser cache:</strong> Clearing your cache can help
          resolve loading issues.
        </li>
      </ul>

      {/* Contact Support */}
      <h2 className="text-2xl font-semibold text-gray-800 mb-2">
        Need Further Assistance?
      </h2>

      <p className="text-gray-700 mb-8">
        For further support, contact the Starrvault support team at{" "}
        <strong>starrvault24@gmail.com</strong>. Provide details about the
        issue, and our team will assist you.
      </p>
    </div>
  );
}

export default StarNoteInfo;
