import { useContext } from "react";
import { Link, Outlet } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import LoginRegisterTile from "../../appComponents/LoginRegisterTile";

function Documentation() {
  const { currentUser } = useContext(AuthContext);

  return (
    <>
      {!currentUser && <LoginRegisterTile />}
      <div
        className={`${
          !currentUser ? "mt-8 ml-6" : "mx-10 -mt-2"
        } flex p-6 border border-gray-300 rounded w-[97%] h-[calc(100vh-1.8rem)]`}
      >
        <div className="border-r border-r-gray-300 pr-4 -my-6">
          <h2 className="text-custom-gray-1 text-2xl font-semibold mt-6 mb-4">
            Documentation
          </h2>
          <ul className="mt-2 space-y-3">
            <Link to="/documentation/about">
              <li className="flex items-center font-medium text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
                About
              </li>
            </Link>

            <Link to="/documentation/registration">
              <li className="flex items-center font-medium text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
                Registration
              </li>
            </Link>

            <Link to="/documentation/login">
              <li className="flex items-center font-medium text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
                Login
              </li>
            </Link>

            <Link to="/documentation/account">
              <li className="flex items-center font-medium text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
                Account
              </li>
            </Link>

            <Link to="/documentation/starchat">
              <li className="flex items-center font-medium text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
                Star Chat
              </li>
            </Link>

            <Link to="/documentation/starnote">
              <li className="flex items-center font-medium text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
                Star Note
              </li>
            </Link>

            <Link to="/documentation/starconnect">
              <li className="flex items-center font-medium text-gray-800 hover:bg-gray-200 p-3 rounded cursor-pointer transition duration-200">
                Star Connect
              </li>
            </Link>
          </ul>
        </div>

        {/* Outlet container with scrollbar */}
        <div className="overflow-y-auto ml-2 w-full custom-scrollbar h-full">
          <Outlet />
        </div>
      </div>
    </>
  );
}

export default Documentation;
