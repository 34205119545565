import React, { createContext, useState, useEffect, useContext } from "react";
import { collection, where, getDocs, query } from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "./AuthContext";

const LabelsContext = createContext();

export const LabelsProvider = ({ children }) => {
  const [labels, setLabels] = useState([]);
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    if (currentUser) {
      const fetchLabels = async () => {
        try {
          const userRef = collection(db, "users");
          const q = query(userRef, where("email", "==", currentUser.email));
          const snapshot = await getDocs(q);

          if (!snapshot.empty) {
            const userDoc = snapshot.docs[0];
            const userLabels = userDoc.data().noteLabels || [];
            setLabels(userLabels);
          }
        } catch (error) {
          console.error("Error fetching labels: ", error);
        }
      };
      fetchLabels();
    }
  }, [currentUser]);

  return (
    <LabelsContext.Provider value={{ labels, setLabels }}>
      {children}
    </LabelsContext.Provider>
  );
};

export const useLabels = () => {
  return useContext(LabelsContext);
};
