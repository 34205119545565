import {
  collection,
  deleteDoc,
  doc,
  getDocs,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db } from "../../firebase";
import { MdLockReset } from "react-icons/md";
import { FiTrash, FiX } from "react-icons/fi";
import { FaCheck, FaSpinner } from "react-icons/fa";
import { AuthContext } from "../../context/AuthContext";
import React, { useState, useRef, useEffect, useContext } from "react";
import { deleteUser, sendPasswordResetEmail, signOut } from "firebase/auth";

function AccountSettings() {
  const modalRef = useRef(null);
  const fileInputRef = useRef(null);
  const [docId, setDocId] = useState();
  const [username, setUsername] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [imageError, setImageError] = useState("");
  const [isUploading, setIsUploading] = useState(false);
  const [loadingState, setLoadingState] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [successState, setSuccessState] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isDAmodalOpen, setIsDAmodalOpen] = useState(false);
  const [isRPmodalOpen, setIsRPmodalOpen] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [isUsernameModalOpen, setIsUsernameModalOpen] = useState(false);

  useEffect(() => {
    // Query to retrieve document ID of current user
    const docIdQuery = query(
      collection(db, "users"),
      where("email", "==", currentUser.email)
    );
    // Fetch info from db
    const rInfo = async () => {
      (await getDocs(docIdQuery)).forEach((doc) => {
        setDocId(doc.id);
      });
    };
    rInfo();
  }, [currentUser.email]);

  useEffect(() => {
    // Function to close modals when clicking outside or on Esc key press
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeAllModals();
      }
    };

    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        closeAllModals();
      }
    };

    const closeAllModals = () => {
      setIsUsernameModalOpen(false);
      setIsRPmodalOpen(false);
      setIsDAmodalOpen(false);
      setIsUploadModalOpen(false);
    };

    // Add event listeners
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup event listeners on unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  // Function to handle changing of username
  const handleUsernameChange = async () => {
    const uc = doc(db, "users", docId);
    await updateDoc(uc, {
      username: username,
    });
    alert("Username has been updated!");
    setIsUsernameModalOpen(false);
  };

  // Function to handle password reset
  const handlePasswordReset = () => {
    sendPasswordResetEmail(auth, currentUser.email)
      .then(() => {
        alert(
          "Password reset email has been sent, check your email and then login with the new password."
        );
        signOut(auth);
        window.location.reload(false);
      })
      .catch((error) => {
        alert("There was an error: " + error.message);
      });
    setIsRPmodalOpen(false);
  };

  // Function to handle account deletion
  const handleAccountDeletion = async () => {
    setIsDAmodalOpen(false);

    // Update the modal to show the loading state
    setIsDAmodalOpen(true);
    setLoadingState(true);

    try {
      // Delete all documents in a colletion
      const deleteCollection = async (collectionName) => {
        const collectionRef = collection(db, collectionName);
        const snapshot = await getDocs(collectionRef);
        for (const doc of snapshot.docs) {
          await deleteDoc(doc.ref);
        }
      };

      // Delete chats where the user is a participant
      const deleteChats = async (field) => {
        const q = query(
          collection(db, "chats"),
          where(field, "==", currentUser.email)
        );
        const snapshot = await getDocs(q);
        for (const doc of snapshot.docs) {
          await deleteDoc(doc.ref);
        }
      };

      // Delete user document
      await deleteDoc(doc(db, "users", docId));

      // Delete user's associated collections if they exist
      const userCollections = [
        `contacts-${currentUser.email}`,
        `notes-${currentUser.email}`,
      ];
      for (const collectionName of userCollections) {
        await deleteCollection(collectionName);
      }

      // Delete chats where the user is a participant
      await deleteChats("participant1");
      await deleteChats("participant2");

      // Delete the user account
      await deleteUser(currentUser);

      setLoadingState(false);
      setSuccessState(true);

      // Delay for 5 seconds before reloading
      setTimeout(() => {
        window.location.reload(false);
      }, 5000);
    } catch (error) {
      alert(
        "An error occured (Try logging in again before attempting this action)" +
          error.message
      );
      // Reset loading state if there's an error
      setLoadingState(false);
    }
  };

  // Function to handle image uploads
  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (!file.type.match("image/jpeg|image/jpg|image/png")) {
        setImageError("File must be an image (jpg, jpeg, png).");
        return;
      }
      if (file.size > 8 * 1024 * 1024) {
        setImageError("Your image exceeds the file size limit of 8MB.");
        return;
      }
      setImageError("");
      setSelectedImage(file);
      setIsUploadModalOpen(true);
      // Simulate image upload
      setIsUploading(true);
      let progress = 0;
      const interval = setInterval(() => {
        progress += 10;
        setUploadProgress(progress);
        if (progress >= 100) {
          clearInterval(interval);
          setIsUploading(false);
        }
      }, 200);
    }
  };

  // Function to CLOSE upload modal
  const handleUploadModalClose = () => {
    if (isUploading) {
      if (window.confirm("Do you want to cancel the image upload?")) {
        setIsUploadModalOpen(false);
        setSelectedImage(null);
        setUploadProgress(0);
      }
    } else {
      setIsUploadModalOpen(false);
    }
  };

  return (
    <div className="p-4 space-y-6">
      <h2 className="mb-4 text-xl font-medium">Account Settings</h2>

      {/* Username Change Card */}
      <div className="bg-white p-6 rounded-md border border-gray-200">
        <h3 className="mb-4 text-lg font-medium ">Change your username:</h3>
        <div className="flex space-x-4">
          <input
            type="text"
            placeholder="Enter new username"
            className="border border-gray-300 p-2 rounded w-full"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
          <button
            className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
            disabled={!username}
            onClick={() => setIsUsernameModalOpen(true)}
          >
            Submit
          </button>
        </div>
      </div>

      {/* Username Change Modal */}
      {isUsernameModalOpen && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Confirm Username Change
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsUsernameModalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Do you want your new username to be <strong>{username}</strong>?
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  className="text-md font-medium text-blue-500 p-2 rounded hover:bg-blue-500 hover:text-white ease-in-out duration-200"
                  onClick={handleUsernameChange}
                >
                  Yes
                </button>
                <button
                  className="text-md font-medium text-gray-500 p-2 rounded hover:bg-gray-500 hover:text-white ease-in-out duration-200"
                  onClick={() => setIsUsernameModalOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="flex space-x-6">
        {/* Image Card */}
        <div className="flex flex-col items-center p-4 rounded-md border border-gray-200 w-48">
          <input
            ref={fileInputRef}
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            className="hidden"
          />
          <h3 className="mb-4 text-lg font-medium self-start">
            Change profile picture:
          </h3>
          <div className="flex items-center space-x-2 mb-4">
            <img
              src={
                selectedImage
                  ? URL.createObjectURL(selectedImage)
                  : "https://via.placeholder.com/80"
              }
              alt="Profile"
              className="w-16 h-16 object-cover rounded-full"
            />
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded flex items-center"
              onClick={() => fileInputRef.current.click()}
            >
              Select
            </button>
          </div>
          {imageError && <p className="mt-2 text-red-500">{imageError}</p>}
          {isUploadModalOpen && (
            <div className="fixed inset-0 bg-gray-900 bg-opacity-50 flex items-center justify-center">
              <div className="bg-white p-6 rounded-lg shadow-lg relative">
                <h3 className="mb-4 text-lg font-medium">Upload Image</h3>
                {selectedImage && (
                  <img
                    src={URL.createObjectURL(selectedImage)}
                    alt="Selected"
                    className="mb-4 w-32 h-32 object-cover rounded-full"
                  />
                )}
                <div className="w-full bg-gray-200 rounded-full h-4 mb-4">
                  <div
                    className="bg-blue-500 h-4 rounded-full"
                    style={{ width: `${uploadProgress}%` }}
                  ></div>
                </div>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded disabled:opacity-50"
                  disabled={uploadProgress < 100}
                  onClick={() => setIsUploadModalOpen(false)}
                >
                  Set Profile Picture
                </button>
                <button
                  className="absolute top-2 right-2 text-gray-500"
                  onClick={handleUploadModalClose}
                >
                  ✕
                </button>
              </div>
            </div>
          )}
        </div>

        {/* Account Actions Card */}
        <div className="bg-white p-6 rounded-md border border-gray-200 flex-grow">
          <h3 className="mb-3 text-lg font-medium text-left">
            Account actions:
          </h3>
          <div className="flex mt-8 justify-center">
            <div className="my-auto items-center p-3 rounded border border-gray-200 mr-12">
              <span>Reset Password:</span>
              <button
                className="text-blue-500 text-sm px-2 py-1 ml-2 rounded hover:text-white hover:bg-blue-500 duration-200 ease-in-out"
                onClick={() => setIsRPmodalOpen(true)}
              >
                Reset
              </button>
            </div>

            <div className="my-auto items-center p-3 rounded border border-gray-200">
              <span>Delete Account:</span>
              <button
                className="text-red-500 text-sm px-2 py-1 ml-2 rounded hover:text-white hover:bg-red-500 duration-200 ease-in-out"
                onClick={() => setIsDAmodalOpen(true)}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Reset Password Modal */}
      {isRPmodalOpen && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Reset Password
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsRPmodalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <div className="text-center">
              <p className="text-gray-600 mb-2">
                Are you sure you want to reset your password?
              </p>
              <button
                className="flex text-md mx-auto font-medium text-blue-500 p-2 rounded hover:bg-blue-500 hover:text-white ease-in-out duration-200"
                onClick={handlePasswordReset}
              >
                <MdLockReset className="text-xl mt-[0.13rem] mr-1" />
                Reset Password
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Account Modal */}
      {isDAmodalOpen && (
        <div className="fixed inset-0 -top-6 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={modalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Delete Account
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsDAmodalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            {loadingState ? (
              <div className="flex items-center justify-center">
                <FaSpinner className="animate-spin text-blue-500 text-2xl mr-3" />
                <span>Deleting your account...</span>
              </div>
            ) : successState ? (
              <div className="flex items-center justify-center">
                <FaCheck className="text-green-500 text-2xl mr-3" />
                <span>Account successfully deleted</span>
              </div>
            ) : (
              <div className="text-center">
                <p className="text-gray-600 mb-2">
                  Are you sure you want to delete your account? This action
                  cannot be undone.
                </p>
                <button
                  className="flex text-md font-medium text-red-500 mx-auto p-2 rounded hover:bg-red-500 hover:text-white ease-in-out duration-200"
                  onClick={handleAccountDeletion}
                >
                  <FiTrash className="mt-[0.20rem] mr-1" />
                  Delete Account
                </button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default AccountSettings;
