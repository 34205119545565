import { useState } from "react";
import { auth } from "../firebase";
import { useNavigate } from "react-router-dom";
import { LuEye, LuEyeOff } from "react-icons/lu";
import { signInWithEmailAndPassword } from "firebase/auth";
import LoginRegisterTile from "../appComponents/LoginRegisterTile";

function Login() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  // Toggle password visibility
  const togglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  // Sanitize input by trimming whitespace
  const sanitizeInput = (input) => {
    return input.trim();
  };

  // Handle form submission and navigate to "/" on success
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Sanitize inputs
    const sanitizedEmail = sanitizeInput(email);
    const sanitizedPassword = sanitizeInput(password);

    try {
      // Sign in the user
      await signInWithEmailAndPassword(auth, sanitizedEmail, sanitizedPassword);
      navigate("/");
    } catch (err) {
      alert("Error", err.message);
    }
  };

  return (
    <>
      <LoginRegisterTile />
      <div className="min-h-[93vh] flex items-center justify-center">
        <form
          onSubmit={handleSubmit}
          className="relative bg-white p-10 rounded-xl flex flex-col items-center space-y-6 w-full max-w-md shadow-2xl"
        >
          {/* Gradient Shadow */}
          <div
            className="absolute inset-0 rounded-xl"
            style={{
              zIndex: -1,
              background: "linear-gradient(135deg, #ffec99, #9f7aea, #3b82f6)",
              filter: "blur(20px)",
            }}
          ></div>

          <h3 className="text-3xl font-mrat font-semibold text-center tracking-tight">
            Login
          </h3>
          <div className="w-full">
            <label htmlFor="email" className="block text-gray-500 mb-1">
              Email
            </label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(sanitizeInput(e.target.value))}
              placeholder="Enter email address"
              className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
          </div>
          <div className="w-full relative">
            <label htmlFor="password" className="block text-gray-500 mb-1">
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(sanitizeInput(e.target.value))}
              placeholder="Enter password"
              className="w-full font-medium text-gray-700 text-lg p-2 rounded border border-gray-300 focus:outline-none focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              required
            />
            <div
              onClick={togglePasswordVisibility}
              className="absolute right-3 top-11 flex items-center cursor-pointer text-gray-500"
            >
              {showPassword ? <LuEye /> : <LuEyeOff />}
            </div>
          </div>
          <button
            type="submit"
            className="w-full bg-indigo-500 hover:bg-indigo-600 text-white py-3 rounded-lg font-semibold duration-150 ease-in-out"
          >
            Login
          </button>
        </form>
      </div>
    </>
  );
}

export default Login;
