import {
  doc,
  query,
  where,
  setDoc,
  getDoc,
  getDocs,
  collection,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FiX } from "react-icons/fi";
import { FaPlus } from "react-icons/fa";
import { HiOutlineSearch } from "react-icons/hi";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineArrowRight } from "react-icons/ai";
import { AuthContext } from "../../context/AuthContext";
import { useContext, useState, useEffect, useRef } from "react";

function Search() {
  let location = useLocation();
  const newChatModalRef = useRef(null);
  const [err, setErr] = useState(false);
  const [users, setUsers] = useState([]);
  const [chats, setChats] = useState([]);
  const chatSearchTermRef = useRef(null);
  const [username, setUsername] = useState("");
  const { currentUser } = useContext(AuthContext);
  const [chatSearchTerm, setChatSearchTerm] = useState("");
  const [showNewChatModal, setShowNewChatModal] = useState(false);

  // Validate user input
  const sanitizeInput = (input) => {
    const temp = document.createElement("div");
    temp.textContent = input;
    return temp.innerHTML;
  };

  // Handle clicks outside new chat modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        newChatModalRef.current &&
        !newChatModalRef.current.contains(event.target)
      ) {
        setShowNewChatModal(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setShowNewChatModal(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  // Existing useEffect for handling clicks outside new chat modal...
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        chatSearchTermRef.current &&
        !chatSearchTermRef.current.contains(event.target) &&
        chatSearchTerm
      ) {
        setChatSearchTerm("");
      }
    };

    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setChatSearchTerm("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [chatSearchTerm]);

  // Handle real-time search for users (Find a user input)
  useEffect(() => {
    const fetchUsers = async () => {
      if (username === "") {
        setUsers([]);
        return;
      }

      setErr(false);
      const sanitizedUsername = sanitizeInput(username);
      const cid = "contacts-" + currentUser.email;

      // Query to search for emails that start with the typed username
      const q = query(
        collection(db, cid),
        where("email", ">=", sanitizedUsername),
        where("email", "<=", sanitizedUsername + "\uf8ff")
      );

      try {
        const querySnapshot = await getDocs(q);
        const fetchedUsers = [];
        querySnapshot.forEach((doc) => {
          fetchedUsers.push(doc.data());
        });
        setUsers(fetchedUsers);
      } catch (err) {
        setErr(true);
      }
    };

    fetchUsers();
  }, [username, currentUser]);

  // Handle real-time search for chats (Find a chat input)
  useEffect(() => {
    const fetchChats = async () => {
      if (chatSearchTerm === "") {
        setChats([]);
        return;
      }

      setErr(false);
      const sanitizedChatSearchTerm = sanitizeInput(chatSearchTerm);

      // Query to search for chats where either participant1 or participant2 matches currentUser.email
      const q1 = query(
        collection(db, "chats"),
        where("participant1", "==", currentUser.email),
        where("participant2", "==", sanitizedChatSearchTerm)
      );

      const q2 = query(
        collection(db, "chats"),
        where("participant2", "==", currentUser.email),
        where("participant1", "==", sanitizedChatSearchTerm)
      );

      try {
        const querySnapshot1 = await getDocs(q1);
        const querySnapshot2 = await getDocs(q2);
        const fetchedChats = [];

        querySnapshot1.forEach((doc) => {
          fetchedChats.push(doc.data());
        });
        querySnapshot2.forEach((doc) => {
          fetchedChats.push(doc.data());
        });

        setChats(fetchedChats);
      } catch (err) {
        setErr(true);
      }
    };

    fetchChats();
  }, [chatSearchTerm, currentUser]);

  // Handle the selection of user chats from the search results
  const handleChatSelect = async (selectedUser) => {
    const combinedId1 = currentUser.email + " - " + selectedUser.email;
    const combinedId2 = selectedUser.email + " - " + currentUser.email;

    try {
      // Check if either of the combined IDs exist in the "chats" collection
      const res1 = await getDoc(doc(db, "chats", combinedId1));
      const res2 = await getDoc(doc(db, "chats", combinedId2));

      // If neither document exists, create a new chat document
      if (!res1.exists() && !res2.exists()) {
        await setDoc(doc(db, "chats", combinedId1), {
          messages: [],
          participant1: currentUser.email,
          participant2: selectedUser.email,
          P1Status: "active",
          P2Status: "active",
        });
      } else {
        alert("Chat already exists");
        setShowNewChatModal(false);
      }
    } catch (err) {
      setErr(true);
    }

    setUsername("");
    setShowNewChatModal(false);
  };

  return (
    <div>
      <div className="relative mb-4">
        <input
          type="text"
          placeholder="Find a chat"
          className="w-full p-2 pl-10 rounded bg-gray-200 text-gray-900 focus:outline-none"
          onChange={(e) => setChatSearchTerm(e.target.value)}
          value={chatSearchTerm}
        />
        <HiOutlineSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
        <div className="absolute right-2 top-1/2 transform -translate-y-1/2 cursor-pointer text-blue-500 transition">
          {location.pathname.includes("/starchat/archive") ? null : (
            <FaPlus
              className="w-4 h-4"
              title="New chat"
              onClick={() => setShowNewChatModal(true)}
            />
          )}
        </div>
      </div>

      {chatSearchTerm && (
        <div className="absolute z-20 mt-2 -ml-4 w-full shadow-2xl max-h-48 overflow-y-auto bg-gray-300 rounded-lg border border-gray-400">
          <div className="absolute bg-gray-300 top-0 transform -rotate-45 left-4 w-0 h-0 border-l-[10px] border-l-transparent border-r-[10px] border-r-transparent border-b-[10px] border-b-gray-300"></div>
          <div className="bg-gray-300 h-full relative">
            <FiX
              className="ml-auto mt-2 mr-2 cursor-pointer text-gray-600 hover:text-gray-800 transition"
              onClick={() => setChatSearchTerm("")}
            />
            {chats.length > 0 ? (
              chats.map((chat) => (
                <div
                  key={`${chat.participant1}-${chat.participant2}`}
                  className="chat-item cursor-pointer bg-gray-100 mx-2 mt-2 mb-3 rounded-md hover:bg-gray-200 transition p-2"
                >
                  <div className="flex items-center space-x-3">
                    <img
                      src="https://via.placeholder.com/40"
                      className="rounded-full"
                      alt="User Avatar"
                    />
                    <div>
                      <p className="text-gray-800">
                        {chat.participant1 === currentUser.email
                          ? chat.participant2
                          : chat.participant1}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="p-2 text-gray-500">No chats found</div>
            )}
          </div>
        </div>
      )}

      {showNewChatModal && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={newChatModalRef}
            className="relative bg-white rounded-lg shadow-lg p-4 w-full max-w-md space-y-3"
          >
            <div className="flex justify-between items-center">
              <h2 className="text-lg font-semibold text-gray-800">New Chat</h2>
              <FiX
                className="text-lg text-gray-600 cursor-pointer hover:text-gray-800 transition"
                onClick={() => setShowNewChatModal(false)}
                title="Close"
              />
            </div>
            <div className="border-t border-gray-200 -mx-4" />
            <div className="relative">
              <HiOutlineSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500" />
              <input
                type="text"
                placeholder="Find a user"
                onChange={(e) => setUsername(e.target.value)}
                value={username}
                className="w-full py-2 pl-10 pr-4 rounded-md bg-gray-100 text-gray-900 focus:ring-2 focus:ring-gray-300 focus:outline-none"
              />
            </div>
            <div className="max-h-48 overflow-y-auto custom-scrollbar space-y-2">
              {users.map((user) => (
                <div
                  key={user.email}
                  className="flex items-center justify-between p-3 rounded-md bg-gray-50 hover:bg-gray-100 cursor-pointer transition"
                  onClick={() => handleChatSelect(user)}
                >
                  <div className="flex items-center space-x-3">
                    <img
                      src="https://via.placeholder.com/40"
                      className="rounded-full"
                      alt={`${user.firstName}'s avatar`}
                    />
                    <div>
                      <p className="font-medium text-gray-800">
                        {user.firstName + " " + user.lastName}
                      </p>
                      <p className="text-sm text-gray-500">{user.email}</p>
                    </div>
                  </div>
                </div>
              ))}
              {err && <span>User not found!</span>}
              <Link
                to="/documentation/starchat"
                className="flex w-24 ml-auto items-center text-blue-500 text-sm hover:underline space-x-1"
              >
                <span>Learn more</span>
                <AiOutlineArrowRight className="text-xs" />
              </Link>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default Search;
