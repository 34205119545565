import {
  doc,
  query,
  where,
  getDoc,
  addDoc,
  getDocs,
  updateDoc,
  collection,
  serverTimestamp,
} from "firebase/firestore";
import { db } from "../../firebase";
import { FiX } from "react-icons/fi";
import Note from "../components/Note";
import { FaPlus } from "react-icons/fa6";
import Search from "../components/Search";
import { AuthContext } from "../../context/AuthContext";
import { useLabels } from "../../context/LabelsContext";
import { useCallback, useContext, useEffect, useRef, useState } from "react";

function StarNoteNotes() {
  const modalRef = useRef(null);
  const { labels } = useLabels();
  const [noteBody, setNoteBody] = useState("");
  const [userNotes, setUserNotes] = useState([]);
  const [noteTitle, setNoteTitle] = useState("");
  const { currentUser } = useContext(AuthContext);
  const collectionTitle = `notes-${currentUser.email}`;
  const [selectedLabel, setSelectedLabel] = useState("");
  const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);

  // Fetch user notes on component mount and when currentUser changes
  useEffect(() => {
    const getUserNotes = async () => {
      if (currentUser) {
        const nq = query(
          collection(db, collectionTitle),
          where("noteStatus", "in", ["active", "starred"])
        );
        const querySnapshot = await getDocs(nq);
        setUserNotes(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    };
    getUserNotes();
  }, [collectionTitle, currentUser]);

  // Function to handle the saving of a new note
  const handleNoteSave = async (e) => {
    e.preventDefault();
    if (!noteTitle.trim() || !noteBody.trim()) {
      alert("Title and body cannot be empty");
      return;
    }

    try {
      const newNoteRef = await addDoc(collection(db, collectionTitle), {
        noteTitle,
        noteBody,
        label: selectedLabel ? [{ labelName: selectedLabel }] : [],
        noteStatus: "active",
        save_timing: serverTimestamp(),
      });

      const newNote = {
        id: newNoteRef.id,
        noteTitle,
        noteBody,
        label: selectedLabel ? [{ labelName: selectedLabel }] : [],
        noteStatus: "active",
      };

      setUserNotes((prevNotes) => [newNote, ...prevNotes]);
      setIsNoteModalOpen(false);
    } catch (err) {
      alert("An error has occurred while saving your note");
    }
  };

  // Function to OPEN modal
  const handleOpenModal = () => {
    setIsNoteModalOpen(true);
  };

  // Function to CLOSE modal
  const handleCloseModal = useCallback(() => {
    if (noteTitle.trim() || noteBody.trim()) {
      setIsConfirmationModalOpen(true);
    } else {
      setIsNoteModalOpen(false);
    }
  }, [noteTitle, noteBody]);

  const handleConfirmClose = () => {
    setIsConfirmationModalOpen(false);
    setIsNoteModalOpen(false);
    setNoteTitle("");
    setNoteBody("");
  };

  // Function to handle the starring of a note
  const handleNoteStarring = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    const noteDoc = await getDoc(noteRef);
    if (!noteDoc.exists()) {
      console.log("No such document!");
      return;
    }
    const currentStatus = noteDoc.data().noteStatus;

    const newStatus = currentStatus === "active" ? "starred" : "active";
    await updateDoc(noteRef, {
      noteStatus: newStatus,
    });

    setUserNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId ? { ...note, noteStatus: newStatus } : note
      )
    );
  };

  // Function to handle the archiving of a note
  const handleNoteArchiving = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, {
      noteStatus: "archived",
    });

    setUserNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
  };

  // Function to handle copying of note content
  const handleNoteCopy = (noteContent) => {
    navigator.clipboard
      .writeText(noteContent)
      .then(() => {
        alert("Note content copied to clipboard");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Function to handle deletion of a note
  const handleNoteDelete = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { noteStatus: "binned" });
    setUserNotes((prevNotes) => prevNotes.filter((note) => note.id !== noteId));
  };

  // Function to assign labels to a note
  const handleLabelAssigning = async (noteId, label) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { label });
    setUserNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === noteId ? { ...note, label } : note))
    );
  };

  // Handle noteModal modal escape/close
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        handleCloseModal();
      }
    };
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        handleCloseModal();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, [handleCloseModal]);

  // Function to handle saving edits to a note
  const handleSaveEdit = async (noteId, updatedNote) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, updatedNote);

    setUserNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId ? { ...note, ...updatedNote } : note
      )
    );
  };

  return (
    <div className="relative flex-1 px-4 pt-3 bg-white">
      {/* Topbar */}
      <div className="flex items-center justify-between min-w-[calc(100vw-43rem)] space-y-4 md:space-y-0 md:space-x-4">
        <h2 className="text-base sm:text-base sm:text-center md:text-xl ml-1 font-medium w-full md:w-auto">
          All Notes
        </h2>
        <div className="w-full md:w-auto mx-auto md:mx-0">
          <Search />
        </div>
        <button
          className="flex items-center p-2 sm:p-3 bg-gray-100 text-gray-800 rounded-3xl hover:shadow hover:shadow-yellow-200 transition duration-200 mt-4 md:mt-0"
          onClick={handleOpenModal}
          title="Create a new note"
        >
          <FaPlus className="text-lg sm:text-xl text-yellow-400 font-extrabold mr-1" />
          <span className="sm:hidden md:text-sm lg:inline">Create Note</span>
        </button>
      </div>

      {/* Notes rendering */}
      <div className="mb-4">
        <Note
          userNotes={userNotes}
          onNoteStarring={handleNoteStarring}
          onNoteArchiving={handleNoteArchiving}
          onNoteCopy={handleNoteCopy}
          onNoteDelete={handleNoteDelete}
          onLabelAssigning={handleLabelAssigning}
          labels={labels}
          showStar={true}
          showArchive={true}
          showDelete={true}
          showCopy={true}
          onNoteSaveEdit={handleSaveEdit}
        />
      </div>

      {/* Note modal */}
      {isNoteModalOpen && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-30">
          <div
            className="relative bg-white pt-6 pr-4 pb-4 rounded-lg shadow-lg w-[26vw]"
            ref={modalRef}
          >
            <button
              className="absolute top-2 right-2 p-2 text-lg text-gray-700 rounded"
              onClick={handleCloseModal}
            >
              <FiX title="Close" />
            </button>
            <h2 className="text-xl text-gray-600 font-semibold -mt-3 ml-3 mb-2">
              Create Note
            </h2>
            <div className="w-[26vw] border border-t-gray-300" />
            <input
              type="text"
              placeholder="Title"
              className="text-2xl text-gray-700 font-medium w-full p-3 focus:outline-none"
              onChange={(e) => setNoteTitle(e.target.value)}
            />
            <textarea
              type="text"
              placeholder="Start writing..."
              className="w-full text-lg text-gray-700 max-h-[40vh] p-2 pl-3 pt-0 -mt-3 mb-2 focus:outline-none custom-scrollbar"
              onChange={(e) => setNoteBody(e.target.value)}
            />
            <select
              className="w-34 p-2 pl-3 mb-2 rounded focus:outline-none"
              onChange={(e) => setSelectedLabel(e.target.value)}
            >
              <option>Select a Label</option>
              {labels.map((label, index) => (
                <option key={index} value={label.labelName}>
                  {label.labelName}
                </option>
              ))}
            </select>
            <div className="flex justify-end mt-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded shadow hover:bg-gray-300 transition duration-200"
                onClick={handleNoteSave}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Note closing confirmation modal */}
      {isConfirmationModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]">
            <h2 className="text-lg text-center font-semibold text-gray-800">
              Note
            </h2>
            <div className="-mx-4 mt-2 mb-4 border border-t-gray-300" />
            <div className="flex flex-col items-center space-y-4">
              <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
                It appears you have written something in this new note. Are you
                sure you want to close the note without saving it?
              </p>
              <button
                className="px-4 py-2 bg-gray-100 text-gray-800 rounded-2xl shadow-md hover:bg-gray-200 transition-colors duration-200"
                onClick={handleConfirmClose}
              >
                Close without saving
              </button>
              <button
                className="px-5 py-2 bg-yellow-400 text-white rounded-2xl shadow-md hover:bg-yellow-500 transition-colors duration-200"
                onClick={() => setIsConfirmationModalOpen(false)}
              >
                Go back and save note
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default StarNoteNotes;
