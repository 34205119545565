import React, { useState, useContext } from "react";
import {
  collection,
  where,
  getDocs,
  updateDoc,
  doc,
  arrayUnion,
  arrayRemove,
  query,
} from "firebase/firestore";
import { db } from "../firebase";
import Sidebar from "./components/Sidebar";
import { AuthContext } from "../context/AuthContext";
import RightPaneView from "./components/RightPaneView";
import { useLabels } from "../context/LabelsContext";

function StarNote({ isNavOpen }) {
  const { labels, setLabels } = useLabels();
  const [editingLabelIndex, setEditingLabelIndex] = useState(null);
  const [editingLabelValue, setEditingLabelValue] = useState("");
  const { currentUser } = useContext(AuthContext);

  // Function to handle adding a new label
  const handleAddLabel = async (labelInput) => {
    if (labelInput.trim() === "") return;

    const newLabel = { labelName: labelInput.trim() };
    const updatedLabels = [...labels, newLabel];
    setLabels(updatedLabels);

    if (currentUser) {
      try {
        const userRef = collection(db, "users");
        const q = query(userRef, where("email", "==", currentUser.email));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const userDoc = snapshot.docs[0];
          const userDocRef = doc(db, "users", userDoc.id);
          await updateDoc(userDocRef, {
            noteLabels: arrayUnion(newLabel),
          });
        }
      } catch (error) {
        console.error("Error adding label: ", error);
      }
    }
  };

  // Function to handle the editing of a new label
  const handleEditLabel = async (index, newValue) => {
    const oldLabel = labels[index];
    const updatedLabels = labels.map((label, i) =>
      i === index ? { labelName: newValue } : label
    );
    setLabels(updatedLabels);
    setEditingLabelIndex(null);
    setEditingLabelValue("");

    if (currentUser) {
      try {
        const userRef = collection(db, "users");
        const q = query(userRef, where("email", "==", currentUser.email));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const userDoc = snapshot.docs[0];
          const userDocRef = doc(db, "users", userDoc.id);

          await updateDoc(userDocRef, {
            noteLabels: arrayUnion({ labelName: newValue }),
          });
          await updateDoc(userDocRef, {
            noteLabels: arrayRemove(oldLabel),
          });
        }
      } catch (error) {
        console.error("Error updating label: ", error);
      }
    }
  };

  // Function to handle the deletion of labels
  const handleDeleteLabel = async (index) => {
    const labelToDelete = labels[index];
    const updatedLabels = labels.filter((_, i) => i !== index);
    setLabels(updatedLabels);

    if (currentUser) {
      try {
        const userRef = collection(db, "users");
        const q = query(userRef, where("email", "==", currentUser.email));
        const snapshot = await getDocs(q);

        if (!snapshot.empty) {
          const userDoc = snapshot.docs[0];
          const userDocRef = doc(db, "users", userDoc.id);
          await updateDoc(userDocRef, {
            noteLabels: arrayRemove(labelToDelete),
          });
        }
      } catch (error) {
        console.error("Error deleting label: ", error);
      }
    }
  };

  return (
    <div
      className={`flex h-[calc(100vh-1.8rem)] -mt-1 transition-margin duration-300 ease-in-out rounded-lg border border-gray-200 ${
        isNavOpen ? "ml-72" : "ml-14"
      }`}
    >
      <Sidebar
        labels={labels}
        editingLabelIndex={editingLabelIndex}
        setEditingLabelIndex={setEditingLabelIndex}
        editingLabelValue={editingLabelValue}
        setEditingLabelValue={setEditingLabelValue}
        handleAddLabel={handleAddLabel}
        handleEditLabel={handleEditLabel}
        handleDeleteLabel={handleDeleteLabel}
      />
      <RightPaneView labelsData={labels} />
    </div>
  );
}

export default StarNote;
