import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { FiX } from "react-icons/fi";

function AboutStarrvault() {
  let createAccountModalRef = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    if (auth.currentUser) {
      setIsLoggedIn(true);
    }
  }, []);

  // Handle clicks outside the modal and trigger CLOSE
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        createAccountModalRef.current &&
        !createAccountModalRef.current.contains(event.target)
      ) {
        setIsModalOpen(false);
      }
    };

    // Handle ESC key press and trigger CLOSE
    const handleEscKey = (event) => {
      if (event.key === "Escape") {
        setIsModalOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("keydown", handleEscKey);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("keydown", handleEscKey);
    };
  }, []);

  return (
    <div className="mx-auto p-6">
      <h1 className="text-3xl font-bold text-gray-800 mb-2">
        About Starrvault
      </h1>

      <p className="text-gray-700 mb-8">
        Starrvault is your ultimate digital companion, designed to provide a
        seamless experience in managing communication, organization, and
        personal data security. It offers a unified platform that brings
        together multiple essential features into one app, enabling users to
        stay connected, productive, and secure in today's fast-paced digital
        world.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-1">Our Vision</h2>

      <p className="text-gray-700 mb-8">
        At Starrvault, we believe in simplifying technology without compromising
        on user control, privacy, or functionality. Our goal is to empower
        individuals and teams with tools that seamlessly integrate into their
        daily routines, enhancing both communication and data management with
        top-notch security.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-4">
        Key Features
      </h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold text-blue-800 mb-2">Star Chat</h3>
          <p className="text-blue-700">
            A secure, intuitive messaging platform where users can chat, share
            files, and engage in voice or video calls. Whether coordinating with
            colleagues or catching up with friends, Star Chat ensures smooth,
            encrypted conversations.
          </p>
        </div>

        <div className="bg-green-50 border-l-4 border-green-400 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold text-green-800 mb-2">Star Note</h3>
          <p className="text-green-700">
            An organized space for all your notes. Whether it's task lists,
            brainstorming ideas, or important reminders, Star Note allows you to
            save, edit, and organize notes effortlessly, with cloud storage
            ensuring access across devices.
          </p>
        </div>

        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold text-yellow-800 mb-2">
            Star Connect
          </h3>
          <p className="text-yellow-700">
            A robust contact management tool that helps you maintain and
            categorize your connections. You can easily add, edit, and access
            your contact details, ensuring that important people are always
            within reach.
          </p>
        </div>

        <div className="bg-red-50 border-l-4 border-red-400 p-4 rounded-lg shadow">
          <h3 className="text-xl font-bold text-red-800 mb-2">
            Security & Encryption
          </h3>
          <p className="text-red-700">
            At the heart of Starrvault is a commitment to security. Each feature
            within the platform is designed with strong encryption protocols,
            ensuring that your conversations, notes, and contact details remain
            private and secure.
          </p>
        </div>
      </div>

      <h2 className="text-2xl font-semibold text-gray-800 mb-1 mt-8">
        Why Choose Starrvault?
      </h2>

      <p className="text-gray-700 mb-8">
        With numerous apps available for communication and organization,
        Starrvault stands out by combining all essential tools into one
        streamlined platform, while maintaining a high level of data privacy and
        security. Whether you're an individual looking for personal organization
        or a professional team needing to communicate securely, Starrvault
        provides the reliability, functionality, and security you need.
      </p>

      <h2 className="text-2xl font-semibold text-gray-800 mb-1">
        Security You Can Trust
      </h2>

      <p className="text-gray-700 mb-8">
        Privacy and security are at the forefront of everything we do at
        Starrvault. We implement state-of-the-art encryption across all our
        services, ensuring that your data is protected from unauthorized access.
        Our platform gives you full control over your information, with
        easy-to-use tools to manage permissions, access, and privacy settings.
      </p>

      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 rounded-lg shadow">
        <h2 className="text-2xl font-semibold text-blue-800 mb-4">
          Getting Started with Starrvault
        </h2>

        <ul className="text-blue-700 list-disc list-inside ml-6">
          <li className="mb-2">
            <strong>Sign up:</strong>{" "}
            {isLoggedIn ? (
              <span
                className="text-purple-700 hover:underline underline-offset-2 cursor-pointer"
                onClick={() => setIsModalOpen(true)}
              >
                Create an account
              </span>
            ) : (
              <Link to="/register">
                <span className="text-purple-700 hover:underline underline-offset-2">
                  Create an account
                </span>
              </Link>
            )}{" "}
            and access all features with ease. Whether for personal use or as
            part of a team, Starrvault simplifies your digital life.
          </li>
          <li className="mb-2">
            <strong>Explore:</strong> Get acquainted with Star Chat, Star Note,
            and Star Connect by exploring the intuitive interface and
            customizing settings to your preferences.
          </li>
          <li className="mb-2">
            <strong>Stay organized:</strong> Enjoy how Starrvault helps you
            manage your daily life with tools that prioritize simplicity,
            functionality, and security.
          </li>
        </ul>
      </div>

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
          <div
            ref={createAccountModalRef}
            className="relative bg-white rounded-lg shadow-md p-4 w-[90%] md:w-[30%] lg:w-[20%]"
          >
            <div className="flex justify-between items-center mb-3 px-1">
              <h2 className="text-lg font-semibold text-gray-800">
                Important Note
              </h2>
              <FiX
                className="text-lg text-gray-700 cursor-pointer"
                onClick={() => setIsModalOpen(false)}
                title="Close"
              />
            </div>
            <div className="-mx-4 mb-4 border border-t-gray-300" />
            <p className="text-gray-600 max-w-[30rem] mb-2 text-center">
              You cannot access the register page as you are <b>logged in</b>{" "}
              and already have an account.
            </p>
          </div>
        </div>
      )}

      <p className="text-gray-700 mt-4 mb-8">
        Starrvault is built for those who want to keep their digital lives
        organized without compromising on security. Join us and discover how
        Starrvault can transform your communication and productivity while
        keeping your data safe.
      </p>
    </div>
  );
}

export default AboutStarrvault;
