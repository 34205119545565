import React from "react";

function Search() {
  return (
    <div>
      <div className="relative">
        <input
          type="text"
          placeholder="Search for a note"
          className="sm:w-[24vw] lg:w-[40vw] p-2 pl-10 rounded bg-gray-200 text-gray-900 focus:outline-none"
        />
        <svg
          className="w-6 h-6 text-gray-900 absolute -mt-8 ml-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-4.35-4.35M17.83 11.25A6.75 6.75 0 1111.25 4.5a6.75 6.75 0 016.58 6.75z"
          ></path>
        </svg>
      </div>
    </div>
  );
}

export default Search;
