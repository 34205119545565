import React from "react";
import { Outlet, useLocation, Link } from "react-router-dom";

function RightPaneView() {
  const location = useLocation();
  const isHome = location.pathname === "/starnote";

  return (
    <div className="custom-scrollbar">
      {isHome ? (
        <div className="flex items-center ml-auto min-w-[calc(100vw-40rem)] min-h-[calc(100vh-2rem)]">
          <div className="text-center mx-auto">
            <h1 className="text-4xl font-semibold text-gray-800 mb-8">
              Notes, secured
            </h1>
            <ul className="space-y-4 text-lg">
              <li>
                <Link to="/settings/starnote" className="text-blue-500">
                  Manage Settings
                </Link>
              </li>
              <li>
                <Link to="/starnote/archive" className="text-blue-500">
                  See Archive
                </Link>
              </li>
              <li>
                <Link to="/documentation/starnote" className="text-blue-500">
                  See Docs
                </Link>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        <Outlet />
      )}
    </div>
  );
}

export default RightPaneView;
