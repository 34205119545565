import { createContext, useState, useEffect, useContext } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "./AuthContext";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [chats, setChats] = useState([]);
  const [usernames, setUsernames] = useState({});
  const { currentUser } = useContext(AuthContext);

  useEffect(() => {
    const getChats = async () => {
      try {
        const chatData = [];
        // Query to get chats where currentUser is participant1
        const p1Query = query(
          collection(db, "chats"),
          where("participant1", "==", currentUser.email)
        );
        const p1Snapshot = await getDocs(p1Query);
        p1Snapshot.forEach((doc) => {
          chatData.push({ ...doc.data(), id: doc.id });
        });

        // Query to get chats where currentUser is participant2
        const p2Query = query(
          collection(db, "chats"),
          where("participant2", "==", currentUser.email)
        );
        const p2Snapshot = await getDocs(p2Query);
        p2Snapshot.forEach((doc) => {
          chatData.push({ ...doc.data(), id: doc.id });
        });
        setChats(chatData);

        // Fetch usernames for other participants
        const usernamesData = {};
        await Promise.all(
          chatData.map(async (chat) => {
            const otherParticipant =
              chat.participant1 === currentUser.email
                ? chat.participant2
                : chat.participant1;

            // Fetch username, if not already
            if (!usernamesData[otherParticipant]) {
              const userQuery = query(
                collection(db, "contacts-" + currentUser.email),
                where("email", "==", otherParticipant)
              );
              const userSnapshot = await getDocs(userQuery);
              userSnapshot.forEach((doc) => {
                const userData = doc.data();
                const username = userData.lastName
                  ? `${userData.firstName} ${userData.lastName}`
                  : userData.firstName;
                usernamesData[otherParticipant] = username;
              });
            }
          })
        );

        setUsernames(usernamesData);
      } catch (err) {
        console.error("Error fetching documents: ", err);
      }
    };

    if (currentUser && currentUser.email) {
      getChats();
    }
  }, [currentUser]);

  return (
    <ChatContext.Provider value={{ chats, usernames }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
