import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebase";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";
import { useLabels } from "../../context/LabelsContext";
import Note from "../components/Note";

function StarNoteArchive() {
  const { labels } = useLabels();
  const { currentUser } = useContext(AuthContext);
  const collectionTitle = `notes-${currentUser.email}`;
  const [archivedNotes, setArchivedNotes] = useState([]);

  // Fetch all the notes saved by user where noteStatus is 'archived'
  useEffect(() => {
    const getArchivedNotes = async () => {
      if (currentUser) {
        const nq = query(
          collection(db, collectionTitle),
          where("noteStatus", "==", "archived")
        );
        const querySnapshot = await getDocs(nq);
        setArchivedNotes(
          querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }))
        );
      }
    };
    getArchivedNotes();
  }, [collectionTitle, currentUser]);

  // Function to handle the deletion of notes
  const handleNoteDelete = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { noteStatus: "binned" });
    setArchivedNotes((prevNotes) =>
      prevNotes.filter((note) => note.id !== noteId)
    );
  };

  // Function to handle unarchiving notes
  const handleNoteUnarchiving = async (noteId) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, {
      noteStatus: "active",
    });

    setArchivedNotes((prevNotes) =>
      prevNotes.filter((note) => note.id !== noteId)
    );
  };

  // Function to handle copying of the note content
  const handleNoteCopy = (noteContent) => {
    navigator.clipboard
      .writeText(noteContent)
      .then(() => {
        alert("Note content copied to clipboad");
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // Function to assign labels to a note
  const handleLabelAssigning = async (noteId, label) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, { label });
    setArchivedNotes((prevNotes) =>
      prevNotes.map((note) => (note.id === noteId ? { ...note, label } : note))
    );
  };

  // Function to handle saving edits to a note
  const handleSaveEdit = async (noteId, updatedNote) => {
    const noteRef = doc(db, collectionTitle, noteId);
    await updateDoc(noteRef, updatedNote);

    setArchivedNotes((prevNotes) =>
      prevNotes.map((note) =>
        note.id === noteId ? { ...note, ...updatedNote } : note
      )
    );
  };

  return (
    <div className="relative rounded-r-lg flex-1 px-4 pt-3 bg-white min-w-[calc(100vw-41rem)]">
      <div className="flex items-center">
        <h2 className="text-xl ml-2 mt-2 font-medium">Archived Notes</h2>
      </div>
      <Note
        labels={labels}
        showCopy={true}
        showStar={false}
        showArchive={false}
        showUnarchive={true}
        userNotes={archivedNotes}
        onNoteCopy={handleNoteCopy}
        onNoteSaveEdit={handleSaveEdit}
        onNoteDelete={handleNoteDelete}
        onNoteArchiving={handleNoteUnarchiving}
        onLabelAssigning={handleLabelAssigning}
      />
    </div>
  );
}

export default StarNoteArchive;
